import React from 'react';

export interface IError {
  message?: string;
}

export interface IApiError {
  error: boolean;
  timestamp: number;
  message: string;
  statusCode: number;
}

export interface IErrorContext {
  error?: IError;
  /**
   * Callback to set the new error, and any associated debugging information
   * that may be helpful.
   *
   * @param message
   * @param debug
   */
  setError: (message: string, debug?: any) => void;

  /**
   * Clears any errors, which should hide the error mesage
   */
  clearError: () => void;
}

const DEFAULT: IErrorContext = {
  setError: () => {},
  clearError: () => {},
};

export const ErrorContext = React.createContext<IErrorContext>(DEFAULT);
export const useErrorContext = () => React.useContext<IErrorContext>(ErrorContext);
