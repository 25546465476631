import { IObligorDocumentType } from '../api/types';

export interface IObligorDocumentTypesByObligorType {
  obligorTypeCode: string;
  obligorDocumentTypeCodeList: string[];
}

class ObligorDocumentTypeFilter {
  static filterDocumentTypesByObligorType(
    obligorTypeCode: string,
    documentTypeList: IObligorDocumentType[],
  ) {
    let filteredDocumentTypeList: IObligorDocumentType[] = [];

    const obligorDocumentTypesByObligorTypeList: IObligorDocumentTypesByObligorType[] = [
      {
        obligorTypeCode: 'Citizen',
        obligorDocumentTypeCodeList: [
          'USBirthCert',
          'Passport',
          'USCitizenBornAbroad',
          'USCISNaturalizationCert',
          'REALIDCard',
          'MID',
          'StateIDCard',
        ],
      },
      { obligorTypeCode: 'LawFirm', obligorDocumentTypeCodeList: ['SS4NotificationLetter'] },
      {
        obligorTypeCode: 'LPR',
        obligorDocumentTypeCodeList: ['LPR', 'MID', 'REALIDCard', 'StateIDCard'],
      },
      {
        obligorTypeCode: 'NonCitizen',
        obligorDocumentTypeCodeList: ['NTA', 'VDOrder', 'IJOrder', 'I220B', 'I765'],
      },
      { obligorTypeCode: 'NonProfit', obligorDocumentTypeCodeList: ['SS4AND947'] },
    ];

    const obligorDocumentTypesByObligorType: IObligorDocumentTypesByObligorType | undefined =
      obligorDocumentTypesByObligorTypeList.find((x) => x.obligorTypeCode === obligorTypeCode);

    documentTypeList.forEach(function (documentType) {
      if (
        obligorDocumentTypesByObligorType?.obligorDocumentTypeCodeList.includes(documentType.code)
      ) {
        filteredDocumentTypeList.push(documentType);
      }
    });

    return filteredDocumentTypeList;
  }
}

export default ObligorDocumentTypeFilter;
