import React, { useContext } from 'react';
import { Button, Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import DHSIcon from '../../assets/images/dhs-large.png';
import { CeBondsUserContext } from '../../contexts/UserContext';
import { useOktaAuth } from '@okta/okta-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faPlus, faUser } from '@fortawesome/free-solid-svg-icons';

const HeaderComponent = () => {
  const ceBondsUser = useContext(CeBondsUserContext);
  let oktaAuth = useOktaAuth();

  return (
    <Navbar bg='dhs-blue' expand='lg' variant='dark' collapseOnSelect>
      <Navbar.Brand className='me-5'>
        <NavLink to='/'>
          <div className='d-flex'>
            <img src={DHSIcon} alt='Logo' className='d-inline-block my-auto mx-3' height='50px' />
            <div className='text-white d-flex flex-column justify-content-center'>
              <h3 className='m-0'>ICE CeBONDS</h3>
            </div>
          </div>
        </NavLink>
      </Navbar.Brand>

      <Navbar.Toggle aria-controls='responsive-navbar-nav' />

      <Navbar.Collapse>
        <Nav.Item>
          <NavLink to='/' className='mx-3 text-light'>
            <FontAwesomeIcon icon={faHouse} className='me-1' />
            Home
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to='/subject' className='mx-3 text-light'>
            <FontAwesomeIcon icon={faPlus} className='me-1' />
            Bond Payment Request
          </NavLink>
        </Nav.Item>
      </Navbar.Collapse>
      <Navbar.Collapse className='justify-content-end'>
        <Nav.Item>
          <a
            className='mx-3 text-light'
            href={process.env.REACT_APP_OKTA_SETTINGS_PATH}
            target='_blank'
          >
            <FontAwesomeIcon icon={faUser} className='me-1' />
            {ceBondsUser?.email}{' '}
          </a>
        </Nav.Item>
        <Nav.Item>
          <Button
            className='mx-3'
            size='sm'
            onClick={() => oktaAuth.oktaAuth.signOut()}
            variant='primary'
          >
            Log out
          </Button>
        </Nav.Item>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default HeaderComponent;
