import { BondStatusEnum } from '../models/status';

export const BondStatusContent = ({ bondStatusCode }: { bondStatusCode: string }) => {
  if (bondStatusCode === BondStatusEnum.NEW_BONDABLE_REQUEST) {
    return (
      <span>
        This bond payment request is waiting for ICE review. A review is normally initiated within
        the same business day.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.SUBJECT_NOT_BONDABLE) {
    return (
      <span>
        There are no matching or no releasable noncitizens for this bond payment request. If you
        need further assistance with your bond payment request, please contact your &nbsp;
        <a href='http://www.ice.gov/contact/ero/' target='_blank'>
          local field office.
        </a>
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.NEW_BOND_REQUEST_RECEIVED_AFTER_HRS) {
    return (
      <span>
        This bond payment request was received after business hours. ICE will conduct a review for
        this request when business hours resume.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.NEW_BOND_REQUEST_VIEWED_BY_OFFICER) {
    return (
      <span>
        This bond payment request is currently under ICE review. The approval of this bond payment
        request is pending the result of the review.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.APPROVED_BONDABLE_REQUEST) {
    return (
      <span>
        ICE has completed its review of your bond payment request and has determined that a bond has
        been set for the noncitizen. You have been approved as an obligor and may proceed with
        completing the bond payment and contract as provided for on ICE Form I-352,{' '}
        <em>Immigration Bond</em>.
        <br />
        <b>Note:</b> The completion of the I-352 and bond payment should be done immediately to help
        ensure the timely processing of the bond and release of the noncitizen.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.DENIED_BONDABLE_REQUEST) {
    return (
      <span>
        ICE has completed the review for this bond payment request and it has been denied. See
        denial reason for more detail.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.I352_SIGNED_BY_OBLIGOR) {
    return (
      <span>
        You have successfully signed your I-352 bond contract. Please proceed with your bond
        payment.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.BOND_PAYMENT_UPLOADED) {
    return (
      <span>
        You have successfully uploaded your bond payment receipt. Your contract may now be submitted
        for review.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.I352_CREATED_IN_EARM) {
    return (
      <span>
        Your I-352 contract and payment have been submitted to ICE. These items will be reviewed by
        ICE.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.I352_VIEWED_BY_OFFICER) {
    return <span>Your I-352 contract and payment are currently under ICE review.</span>;
  }
  if (bondStatusCode === BondStatusEnum.APPROVED_I352_REQUEST) {
    return (
      <span>
        Your I-352 contract has been approved. The release process for the associated noncitizen
        will proceed.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.DENIED_I352_REQUEST) {
    return (
      <span>
        Your bond has been denied. See the related reason for more detail. Your bond payment will be
        returned. There are no additional actions you need to take.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.INVALID_I352) {
    return (
      <span>
        After review, it has been determined that your I-352 contract is invalid. See the related
        reason for more detail.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.BOND_WITHDRAWN) {
    return (
      <span>This bond payment request has been withdrawn. No further action will be taken.</span>
    );
  }
  if (bondStatusCode === BondStatusEnum.SUBJECT_RELEASED) {
    return <span>The noncitizen has been released from ICE custody.</span>;
  }

  return <span>Content not found for: {bondStatusCode}</span>;
};
