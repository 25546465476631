import { Link, useLocation } from 'react-router-dom';
import { Button, Container, Form, Modal, Spinner, Table } from 'react-bootstrap';
import { BondableRequest } from '../models/bond-data';
import { useEffect, useState } from 'react';
import { IBondRequestComments, ICeBondsSubject, IBondRequestDocument } from '../api/types';
import { BondStatusContent } from '../components/BondStatusContent';
import { SubjectDisplay } from '../components/SubjectDisplay';
import CommentsApi from '../api/CommentsApi';
import ObligorDocumentApi from '../api/ObligorDocumentApi';
import Utils from '../api/Utils';
import LoadingPage from './util/LoadingPage';
import BondStatusUtil from '../helper/BondStatusUtil';
import { IApiError, useErrorContext } from '../contexts/ErrorContext';
import { BondActionLinks } from '../components/BondActionLinks';
import { statusesForComments } from '../models/status';
import SubjectSearchApi from '../api/SubjectSearchApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faDownload } from '@fortawesome/free-solid-svg-icons';

export const BondRequestPage = () => {
  const location = useLocation();
  const error = useErrorContext();

  const bondRequestId = location.state as number;
  const [bondRequest, setBondRequest] = useState<BondableRequest>();
  const [ceBondsSubject, setCeBondsSubject] = useState<ICeBondsSubject>();
  const [bondRequestComments, setBondRequestComments] = useState<IBondRequestComments>();
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [isCommentSaving, setIsCommentSaving] = useState<boolean>(false);
  const [isCommentsLoading, setIsCommentsLoading] = useState<boolean>(false);
  const [isContractLoading, setIsContractLoading] = useState(true);
  const [fileControlFeedback, setFileControlFeedback] = useState<string>();
  const [bondContract, setBondContract] = useState<IBondRequestDocument>();

  useEffect(() => {
    document.title = 'Bond Payment Request - ICE CeBONDS';

    // Get fresh bondRequest object
    if (bondRequestId) {
      SubjectSearchApi.GetBondRequest(bondRequestId).then((cbr) => {
        setBondRequest(cbr);
      });

      ObligorDocumentApi.getBondRequestContract(bondRequestId).then((contract) => {
        if (contract && 'bondReqDocId' in contract) {
          setBondContract(contract);
        }
        setIsContractLoading(false);
      });

      if (!bondRequestComments) {
        refreshComments();
      }
    }
  }, []);

  useEffect(() => {
    if (bondRequest) {
      setCeBondsSubject({
        firstName: bondRequest.firstName,
        lastName: bondRequest.lastName,
        aNumber: bondRequest.anumber,
      });
    }
  }, [bondRequest]);

  const filterDenialReason = (denialReason: string) => {
    if (denialReason === 'Alien in transit') {
      return 'Noncitizen in transit';
    }
    if (denialReason === 'Alien ineligible for a bond') {
      return 'Noncitizen cannot be released on bond';
    }

    return denialReason;
  };

  const refreshComments = () => {
    if (bondRequestId) {
      setIsCommentsLoading(true);
      CommentsApi.getComments(bondRequestId).then((comments) => {
        if (!Utils.isError(comments)) {
          setBondRequestComments(comments as IBondRequestComments);
          setIsCommentsLoading(false);
        }
      });
    }
  };

  const cleanupCommentModal = () => {
    setIsCommentSaving(false);
    setShowCommentModal(false);
  };

  const handleShowCommentModal = () => {
    setCommentValidated(false);
    setShowCommentModal(true);
  };

  const handleHideCommentModal = () => setShowCommentModal(false);

  const [commentValidated, setCommentValidated] = useState(false);
  const handleCommentSave = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    const form = event.currentTarget.form;
    const fileControl = form?.fileSelectControl;
    const file = fileControl.files[0];
    error.clearError();

    fileControl.setCustomValidity('');
    if (file) {
      if (file.size > 20000000) {
        // file size limit is 20 MB
        setFileControlFeedback('The selected file is too large. The file size limit is 20 MB.');
        fileControl.setCustomValidity('TooLarge');
      } else if (file.type != 'application/pdf') {
        setFileControlFeedback('Type of selected file is not accepted. Accepted types: .pdf');
        fileControl.setCustomValidity('TypeNotAccepted');
      }
    }
    fileControl.reportValidity();

    if (form?.checkValidity()) {
      // save comment info
      setIsCommentSaving(true);

      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        console.log('file ', file, fileControl);

        console.log('uploading file...');
        CommentsApi.addCommentAttachment(bondRequestId, formData)
          .then((response) => {
            if (Utils.isError(response)) {
              const errResponse = response as IApiError;
              error.setError(errResponse.message);
            } else if ('drsId' in response) {
              CommentsApi.addComment(
                bondRequestId,
                form.commentControl.value,
                response.drsId,
                response.docType,
              ).then(refreshComments);
            }
          })
          .finally(cleanupCommentModal);
      } else {
        CommentsApi.addComment(bondRequestId, form.commentControl.value)
          .then(() => {
            cleanupCommentModal();
          })
          .finally(refreshComments);
      }
    }

    setCommentValidated(true);
  };

  const downloadAttachment = (drsId: number | undefined) => {
    if (drsId) {
      CommentsApi.getCommentAttachment(drsId).then((response) => {
        if ('base64' in response) {
          Utils.downloadPDF(response.base64, response.name);
        }
      });
    }
  };

  const downloadContract = () => {
    if (bondContract) {
      ObligorDocumentApi.getBondRequestDocumentContent(bondContract?.bondReqDocId).then(
        (response) => {
          if ('base64' in response) {
            Utils.downloadPDF(response.base64, response.name);
          }
        },
      );
    }
  };

  const senderCodeText = (senderCode: string) => {
    if (senderCode === 'ERO' || senderCode === 'ICE') {
      return 'ICE';
    } else if (senderCode === 'Obligor') {
      return 'Obligor';
    }

    return '?senderCode?';
  };

  if (!bondRequest || !bondRequestComments || isContractLoading) {
    return <LoadingPage message={'Please Wait...'} />;
  }

  return (
    <Container className='mt-3'>
      <h4>Bond Payment Request</h4>
      <div className='mb-3'>
        This bond payment request was created on {Utils.formatDisplayDate(bondRequest?.createDate)}
      </div>
      <SubjectDisplay ceBondsSubject={ceBondsSubject} />
      <div className='mb-3'>
        <div className='fw-bold'>Current Status</div>
        <div>
          <span>
            <b>{BondStatusUtil.getBondStatusDescription(bondRequest)}</b>
          </span>
          <span className='ms-2'>
            (Status Date: {Utils.formatDisplayDate(bondRequest?.actionDateTime)})
          </span>
        </div>
        <div>
          <BondStatusContent bondStatusCode={bondRequest?.statusCode?.code} />
        </div>
        {bondRequest?.denialReason && (
          <div className='mt-3'>Denial Reason: {filterDenialReason(bondRequest?.denialReason)}</div>
        )}
      </div>
      <div className='mb-3'>
        <div className='fw-bold'>Actions</div>
        <div>
          <BondActionLinks bondRequest={bondRequest} />
        </div>
      </div>
      <div className='mb-3'>
        <div className='fw-bold'>Comments</div>
        {bondRequestComments.comments.length > 0 && (
          <Table size='sm' responsive striped borderless hover className='mb-0'>
            <thead>
              <tr>
                <th>Date</th>
                <th>Sender</th>
                <th>Comment</th>
                <th>Attachment</th>
              </tr>
            </thead>
            <tbody>
              {bondRequestComments.comments.map((bondRequestComment) => (
                <tr key={bondRequestComment.id} data-testid='result-row'>
                  <td>
                    {new Intl.DateTimeFormat('default').format(
                      Date.parse(bondRequestComment.created),
                    )}
                  </td>
                  <td>{senderCodeText(bondRequestComment.senderCode)}</td>
                  <td>{bondRequestComment.comment}</td>
                  <td>
                    {bondRequestComment.drsId && (
                      <Button
                        className='p-0'
                        variant={'link'}
                        onClick={() => downloadAttachment(bondRequestComment.drsId)}
                      >
                        View
                      </Button>
                    )}
                    {!bondRequestComment.drsId && <span>None</span>}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {isCommentsLoading && (
          <div className='pb-3'>
            <Spinner animation='border' role='status' size='sm' className='me-1' />
            Loading...
          </div>
        )}
        {statusesForComments.includes(bondRequest.statusCode.code) && (
          <Button size='sm' variant='secondary' onClick={handleShowCommentModal}>
            Add
          </Button>
        )}
        {!statusesForComments.includes(bondRequest.statusCode.code) && (
          <span>
            New comments cannot be added at this time. The current status of this bond payment
            request does not allow for new comments.
          </span>
        )}
      </div>
      <div className='mb-3'>
        <div className='fw-bold'>ICE Forms</div>
        {!bondContract && <div>No forms to display at this time.</div>}
        {bondContract && (
          <>
            <div>
              <Button className='p-0' variant={'link'} onClick={() => downloadContract()}>
                I-352 Immigration Bond Contract <FontAwesomeIcon icon={faDownload} />
              </Button>
            </div>
            <div>
              <a href='https://www.ice.gov/doclib/forms/i352a.pdf' target='_blank'>
                I-352A IRS Withholding Notice <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </a>
            </div>
            <div>
              <a href='https://www.ice.gov/doclib/forms/i333.pdf' target='_blank'>
                I-333 Obligor Change of Address <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </a>
            </div>
          </>
        )}
      </div>
      <div className='pt-1'>
        <Link to='/'>
          <Button className='me-3'>Home</Button>
        </Link>
        <Link to='/bond-status-container' state={ceBondsSubject}>
          <Button className='me-3'>Go To Noncitizen</Button>
        </Link>
      </div>
      <Modal show={showCommentModal} onHide={handleHideCommentModal}>
        <Form name='commentForm' noValidate validated={commentValidated}>
          <Modal.Header closeButton>
            <Modal.Title>Add Comment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Form.Group className='mb-3'>
                <Form.Label>
                  Your Comment{' '}
                  <span className='text-muted'>(Maximum allowed characters is 4000)</span>
                </Form.Label>
                <Form.Control
                  as='textarea'
                  name='commentControl'
                  required
                  maxLength={4000}
                  rows={4}
                />
                <Form.Control.Feedback type='invalid'>
                  Please enter a comment.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId='formFile' className='mb-1'>
                <Form.Label>Optional Attachment</Form.Label>
                <Form.Control type='file' name='fileSelectControl' accept='application/pdf' />
                <Form.Control.Feedback type='invalid'>{fileControlFeedback}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isCommentSaving && (
              <div>
                <Spinner animation='border' role='status' size='sm' className='me-1' />
                Please Wait...
              </div>
            )}
            <Button variant='primary' onClick={handleCommentSave} disabled={isCommentSaving}>
              Save
            </Button>
            <Button variant='secondary' onClick={handleHideCommentModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};
