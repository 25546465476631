import { BondableRequest } from '../models/bond-data';
import { BondPayment } from '../models/bond-data';
import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Spinner, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faChevronRight,
  faDownload,
  faExclamationCircle,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import PaymentApi from '../api/PaymentApi';
import { DgsApi } from '../api/DgsApi';
import LoadingPage from './util/LoadingPage';
import { IACHform, IFedWireForm, IPaymentForm } from '../models/dgs-forms';
import ObligorApi from '../api/ObligorApi';
import { useCeBondsUserContext } from '../contexts/UserContext';
import { IObligor } from '../api/types';
import Utils from '../api/Utils';
import { IApiError, useErrorContext } from '../contexts/ErrorContext';

export const BondPaymentUpload = () => {
  const location = useLocation();
  const error = useErrorContext();

  const bondableRequest = location.state as BondableRequest;
  const [isPaymentLoading, setIsPaymentLoading] = React.useState(true);
  const [isObligorLoading, setIsObligorLoading] = React.useState(true);
  const [bondPayment, setBondPayment] = useState<BondPayment>();
  const ceBondsUser = useCeBondsUserContext();
  const [obligor, setObligor] = useState<IObligor>();

  useEffect(() => {
    document.title = 'Bond Payment - ICE CeBONDS';

    //load bond payment proof document if exists
    if (bondableRequest && !bondPayment) {
      PaymentApi.getBondPayment(bondableRequest.bondRequestId).then((proofDoc) => {
        setBondPayment(proofDoc);
        setIsPaymentLoading(false);
      });
    }

    if (!obligor) {
      ObligorApi.getObligor().then((cbo) => {
        if (cbo && cbo.obligorId) {
          setObligor(cbo);
        }
        setIsObligorLoading(false);
      });
    }
  }, []);

  const [isBondPaymentSaving, setIsBondPaymentSaving] = useState<boolean>();
  const [isBondPaymentDownloading, setIsBondPaymentDownloading] = useState<boolean>();
  const [bondPaymentFeedback, setBondPaymentFeedback] = useState<string>();
  const [bondPaymentValidated, setBondPaymentValidated] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const handleDocumentModalClose = () => setShowDocumentModal(false);

  const handleShowDocumentModal = () => {
    setIsBondPaymentSaving(false);
    setBondPaymentValidated(false);
    setShowDocumentModal(true);
  };

  const handleBondPaymentUpload = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    const form = event.currentTarget.form;
    const paymentFileControl = form?.paymentFileSelectControl;
    const paymentFile = paymentFileControl.files[0];

    if (paymentFileControl.validity.valueMissing) {
      setBondPaymentFeedback('Please select a file to upload.');
      paymentFileControl.setCustomValidity('ValueMissing');
    } else if (paymentFile.size > 20000000) {
      // file size limit is 20 MB
      setBondPaymentFeedback('The selected file is too large. The file size limit is 20 MB.');
      paymentFileControl.setCustomValidity('TooLarge');
    } else if (paymentFile.type != 'application/pdf') {
      setBondPaymentFeedback('Type of selected file is not accepted. Accepted types: .pdf');
      paymentFileControl.setCustomValidity('TypeNotAccepted');
    } else {
      form?.paymentFileSelectControl.setCustomValidity('');
    }
    paymentFileControl.reportValidity();

    if (form?.checkValidity()) {
      const formData = new FormData();
      formData.append('file', paymentFile);

      setIsBondPaymentSaving(true);

      PaymentApi.saveBondPayment(bondableRequest.bondRequestId, formData)
        .then((bp) => {
          // bond payment proof save succeeded
          setBondPayment(bp);
          setIsBondPaymentSaving(false);
          setShowDocumentModal(false);
        })
        .catch((reason) => {
          setShowDocumentModal(false);
          error.setError('save payment document error ', reason);
        });
    }

    setBondPaymentValidated(true);
  };

  const handleBondPaymentDownload = () => {
    console.log('downloading bond payment...');
    if (bondPayment) {
      setIsBondPaymentDownloading(true);
      PaymentApi.downloadBondPaymentFile(bondableRequest.bondRequestId).then((blob) => {
        var url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', bondPayment.name);
        link.style.visibility = 'hidden';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setIsBondPaymentDownloading(false);
      });
    }
  };

  const handleInstructionDownload = () => {
    let obligorAddress = '';
    if (obligor) {
      obligorAddress += obligor.street1 + ', ';
      obligorAddress += obligor.street2 ? obligor.street2 + ', ' : '';
      obligorAddress += obligor.street3 ? obligor.street3 + ', ' : '';
      obligorAddress += obligor.city + ', ' + obligor.stateCd + ' ' + obligor.zip;
    }

    //Get merged payment instructions PDF
    const paymentPayload: IPaymentForm = {
      bondAmount: '$' + bondableRequest.bondAmount?.toString(),
      receiverABARoutingNum: '021030004',
      typeSubtype: '1000',
      beneficiaryName: 'ICECeBONDSPAYMENTS',
      beneficiaryIdAcctNum: 'Gateway Account: 870191513001',
      receiverABAShortName: 'TREAS  NYC',
      originator: obligor?.name || obligor?.lastName + ', ' + obligor?.firstName,
      businessFuncCode: 'CTR',
      originatorToBeneInfo1: 'Bond # ' + bondableRequest.earmBondNumber,
      originatorToBeneInfo2: obligorAddress,
      originatorToBeneInfo3: '',
      originatorToBeneInfo4: '',
      effectiveEntryDate: new Date().toDateString(),
      abaRoutingNumber: '051036706',
      standardEntryClassCode: 'CCD+',
      paymentRelatedInfo:
        'RMR*IV*' + bondableRequest.earmBondNumber + '**' + bondableRequest.bondAmount + '\\',
      transactionCode: '22',
      agencyAccountNumber: '870191513001',
      bondNumber: bondableRequest.earmBondNumber ? bondableRequest.earmBondNumber.toString() : '',
      nameOfPayorObligor: obligor?.name || obligor?.lastName + ', ' + obligor?.firstName,
      receivingCompanyName: 'ICECeBONDSPAYMENTS',
    };

    DgsApi.getPaymentForm('pay-instructions', paymentPayload)
      .then((formResponse) => {
        if (formResponse['message'] === 'SUCCESS') {
          //Display PDF in new tab
          window.open('/dgsng/forms/download/' + formResponse.fileName, '_new');
        } else {
          console.error('Failed to generate payment PDF: ', formResponse);
        }
      })
      .catch((err) => console.log('exception on generate payment PDF', err));
  };

  if (isPaymentLoading || isObligorLoading) {
    return <LoadingPage message={'Please Wait...'} />;
  }

  return (
    <Container>
      {/* <Trail step={3}></Trail> */}
      <h3>Bond Payment</h3>
      <div>Follow the steps below to complete the bond payment.</div>
      <div>
        <div className='fw-bold mt-3'>Step 1</div>
        <div>Review the payment information below.</div>
        <div>Obligor Name: {obligor?.name || obligor?.lastName + ', ' + obligor?.firstName}</div>
        <div>Bond Number: {bondableRequest.earmBondNumber}</div>
        <div>
          Payment amount:&nbsp;
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
            bondableRequest.bondAmount,
          )}
        </div>
      </div>
      <div>
        <div className='fw-bold mt-3'>Step 2</div>
        <div>
          If the payment information above is correct, then click the "Display/Print Payment
          Instructions" button.
          <OverlayTrigger
            placement='bottom'
            overlay={
              <Tooltip>
                ACH and Fedwire are the available bond payment options. Contact your bank or
                finiancial institution to determine which option is best for you.
              </Tooltip>
            }
          >
            <a href='javascript:;' aria-label='Payment instructions tip'>
              <small>
                <FontAwesomeIcon className='ms-1' icon={faQuestionCircle} />
              </small>
            </a>
          </OverlayTrigger>
        </div>
        <div>
          <Button variant='secondary' size='sm' onClick={handleInstructionDownload}>
            Display/Print Payment Instructions
          </Button>
        </div>
      </div>
      <div>
        <div className='fw-bold mt-3'>Step 3</div>
        <div>
          Take the printed or downloaded payment instructions (see Step 2) to your bank to execute
          the payment transaction.
        </div>
      </div>
      <div>
        <div className='fw-bold mt-3'>Step 4</div>
        <div>Upload a copy of your payment receipt from your bank to provide proof of payment.</div>
        <div>
          {!bondPayment ||
            (!bondPayment.paymentId && (
              <div className='mb-1'>
                <div className='mb-2'>
                  <FontAwesomeIcon icon={faExclamationCircle} color={'orange'} /> You have not
                  uploaded a payment document. Please add your proof of payment document.
                </div>
                <div>
                  <Button variant='secondary' size='sm' onClick={handleShowDocumentModal}>
                    Add Document
                  </Button>
                </div>
              </div>
            ))}

          {bondPayment && bondPayment.paymentId && (
            <div>
              <div className='fw-bold mt-2'>
                <FontAwesomeIcon icon={faCheckCircle} color={'green'} /> You have uploaded the
                following bond payment document. Click "Back" to proceed with submitting your bond
                contract and payment.
              </div>
              <div className='mt-1'>
                {bondPayment.name}
                <Button
                  className='ms-2'
                  variant='secondary'
                  size='sm'
                  onClick={handleBondPaymentDownload}
                >
                  View <FontAwesomeIcon icon={faDownload} />
                </Button>
                <Button
                  className='ms-2'
                  variant='secondary'
                  size='sm'
                  onClick={handleShowDocumentModal}
                >
                  Replace
                </Button>
              </div>
              {isBondPaymentDownloading && (
                <div>
                  <Spinner animation='border' role='status' size='sm' />
                  &nbsp; Please Wait...
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className='mt-4'>
        <Link to='/bond-bundle' state={bondableRequest}>
          <Button variant='primary'>
            Next
            <FontAwesomeIcon icon={faChevronRight} className='ms-1' />
          </Button>
        </Link>
      </div>
      <Modal show={showDocumentModal} onHide={handleDocumentModalClose}>
        <Form name='bondPaymentUploadForm' noValidate validated={bondPaymentValidated}>
          <Modal.Header closeButton>
            <Modal.Title>Bond Payment Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Form.Group controlId='formFile' className='mb-2'>
                <Form.Control
                  type='file'
                  name='paymentFileSelectControl'
                  accept='application/pdf'
                  required
                />
                <Form.Control.Feedback type='invalid'>{bondPaymentFeedback}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isBondPaymentSaving && (
              <span className='mr-3'>
                <Spinner animation='border' role='status' size='sm' className='me-1' />
                Please Wait...
              </span>
            )}
            <Button
              variant='primary'
              onClick={handleBondPaymentUpload}
              disabled={isBondPaymentSaving}
            >
              Upload File
            </Button>
            <Button variant='secondary' onClick={handleDocumentModalClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};
