import Api from './Api';
import {
  IBondRequestDocument,
  IBondRequestDocumentContent,
  ICeBondsI352Submit,
  IObligorDocument,
  IObligorDocumentType,
} from './types';

class ObligorDocumentApi {
  static getRecentObligorDocument(obligorId: number): Promise<IObligorDocument> {
    return Api.GET('/document/obligor/recentdocument/' + obligorId).catch((e) => undefined);
  }

  static getObligorDocumentList(obligorId: number): Promise<IObligorDocument[]> {
    return Api.GET('/document/obligor/alldocs-no-content/' + obligorId).catch((e) => undefined);
  }

  static getObligorDocumentWithContent(obligorDocumentId: number): Promise<IObligorDocument> {
    return Api.GET('/document/obligor/' + obligorDocumentId).catch((e) => undefined);
  }

  static saveObligorDocument(documentTypeCode: string, file: FormData): Promise<IObligorDocument> {
    const endpoint = '/document/obligor/add?documentTypeCd=' + documentTypeCode;
    return Api.POST_file(endpoint, file).then((response) => response as IObligorDocument);
  }

  static getObligorDocumentTypes(): Promise<IObligorDocumentType[]> {
    return Api.GET('/document/obligor/typecodes').then(
      (response) => response as IObligorDocumentType[],
    );
  }

  static addObligorI352(input: ICeBondsI352Submit) {
    return Api.POST('/document/obligor/i352', JSON.stringify(input));
  }

  static getBondRequestContract(bondId: number): Promise<IBondRequestDocument> {
    return Api.GET(`/document/bondcontract/${bondId}`).then(
      (value) => value as IBondRequestDocument,
    );
  }

  static getBondRequestDocumentContent(bondDocId: number): Promise<IBondRequestDocumentContent> {
    return Api.GET(`/document/bondrequest/content/${bondDocId}`).then(
      (value) => value as IBondRequestDocumentContent,
    );
  }
}

export default ObligorDocumentApi;
