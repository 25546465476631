import React, { useState } from 'react';
import { Button, Container, Spinner } from 'react-bootstrap';
import FormModelSheetSignatureInput from '../../components/FormModelSheetSignatureInput';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DgsApi } from '../../api/DgsApi';
import { I352Form } from '../../models/i352-form';
import LoadingPage from '../util/LoadingPage';
import Utils from '../../api/Utils';
import ObligorDocumentApi from '../../api/ObligorDocumentApi';
import SubjectSearchApi from '../../api/SubjectSearchApi';

export interface ISignI352Props {}

const SignI352 = (props: ISignI352Props) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [signatureImage, setSignatureImage] = useState('');

  // https://github.com/remix-run/react-router/issues/8503
  // @ts-ignore
  const [activeFile, setActiveFile] = useState(state?.fileName);
  const [loadingFile, setLoadingFile] = useState(false);
  const [signCount, setSignCount] = useState(0);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // @ts-ignore
  const formData = state?.formData;
  // @ts-ignore
  const bondRequest = state?.bondRequest;
  // @ts-ignore
  const nonCitizenAddress = state?.nonCitizenAddress;

  const doSignForm = () => {
    const now = new Date();

    const newFormData: I352Form = {
      ...formData,
      signed: now.getDate().toString() + Utils.getDateSuffix(now),
      my: Utils.getMonthString(now) + ' ' + now.getFullYear(),
      ObligorSignature: signatureImage,
    };

    setLoadingFile(true);
    DgsApi.getForm('ice-i352', newFormData).then((formResponse) => {
      if (formResponse['message'] === 'SUCCESS') {
        setLoadingFile(false);
        setActiveFile(formResponse.fileName);
        setSignCount(signCount + 1);
      }
    });
  };

  const submitSignedForm = () => {
    setDisableSubmitButton(true);
    setIsSaving(true);

    const bondRequestId = bondRequest.bondRequestId;

    ObligorDocumentApi.addObligorI352({
      obligorId: '',
      bondRequestId,
      fileName: activeFile,
      nonCitizenAddress: nonCitizenAddress,
    }).then((response) => {
      if (response && response.drsId > 0) {
        // I352 was signed and saved.
        // Now get a new copy of the bond request because bond number was added.
        SubjectSearchApi.GetBondRequest(bondRequest.bondRequestId).then((cbr) => {
          navigate('/bond-bundle', { state: cbr });
        });
      } else {
        setDisableSubmitButton(false);
        throw 'Error saving I-352';
      }
    });
  };

  const renderFile = () => {
    return loadingFile ? (
      <LoadingPage message={'Loading file...'} />
    ) : (
      <iframe className={'w-100 h-100'} src={`/dgsng/forms/download/${activeFile}`}>
        PDF
      </iframe>
    );
  };

  const renderConfirmation = () => {
    if (signCount > 0) {
      return (
        <>
          <p>Click the Save button when satisfied with the signed document. </p>
          <Button className='me-2' onClick={submitSignedForm} disabled={disableSubmitButton}>
            Save
          </Button>
        </>
      );
    }
  };

  return (
    <Container fluid={true} className={'d-flex justify-content-center min-vh-100 w-100'}>
      <div className={'w-75'}>{renderFile()}</div>
      <div className={'w-25 p-2'} style={{ backgroundColor: 'lightgray' }}>
        <p>Please sign here</p>
        <FormModelSheetSignatureInput
          onChange={(signatureImage) => setSignatureImage(signatureImage)}
          onSign={doSignForm}
          scaleHeight={20}
        />
        <div className={'ms-2 mt-4'}>
          {renderConfirmation()}
          <Link to='/i352' state={bondRequest}>
            <Button variant='secondary' className='me-3'>
              Cancel
            </Button>
          </Link>
        </div>
        {isSaving && (
          <div className='m-2'>
            <Spinner animation='border' role='status' size='sm' />
            &nbsp; Please Wait...
          </div>
        )}
      </div>
    </Container>
  );
};

export default SignI352;
