import React from 'react';
import { IError } from '../contexts/ErrorContext';
import ErrorPage from '../pages/util/ErrorPage';

export interface IErrorBannerProps {
  error?: IError;
  onDismiss: () => void;
}

const ErrorBanner = ({ error, onDismiss }: IErrorBannerProps) => {
  if (error) {
    return (
      <ErrorPage
        message={error.message || 'Unknown error'}
        slim={true}
        fullWidth={true}
        showClose={true}
        onBannerDismiss={onDismiss}
      />
    );
  } else {
    return null;
  }
};

export default ErrorBanner;
