import { BondableRequest } from '../models/bond-data';
import { BondStatusEnum } from '../models/status';

class BondStatusUtil {
  static getBondStatusDescription(bondRequest: BondableRequest): string {
    if (bondRequest?.statusCode?.code === BondStatusEnum.NEW_BONDABLE_REQUEST) {
      return 'Awaiting ICE Review';
    }
    if (bondRequest?.statusCode?.code === BondStatusEnum.SUBJECT_NOT_BONDABLE) {
      return 'No Matching Noncitizens'; // EARM has automatically rejected the request prior to any ICE review.
    }
    if (bondRequest?.statusCode?.code === BondStatusEnum.NEW_BOND_REQUEST_RECEIVED_AFTER_HRS) {
      return 'After Business Hours';
    }
    if (bondRequest?.statusCode?.code === BondStatusEnum.NEW_BOND_REQUEST_VIEWED_BY_OFFICER) {
      return 'Under ICE Review';
    }
    if (bondRequest?.statusCode?.code === BondStatusEnum.APPROVED_BONDABLE_REQUEST) {
      if (bondRequest.bondType === 'OS') {
        return 'Ready for Payment with I-220B'; // The request has been approved with condition per ICE review
      }
      return 'Ready for Payment'; // The request has been approved per ICE review
    }
    if (bondRequest?.statusCode?.code === BondStatusEnum.DENIED_BONDABLE_REQUEST) {
      return 'Not Releasable'; // The request has been denied per ICE review
    }
    if (bondRequest?.statusCode?.code === BondStatusEnum.I352_CREATED_IN_EARM) {
      return 'I-352 Awaiting Review';
    }
    if (bondRequest?.statusCode?.code === BondStatusEnum.I352_SIGNED_BY_OBLIGOR) {
      return 'I-352 Signed';
    }
    if (bondRequest?.statusCode?.code === BondStatusEnum.BOND_PAYMENT_UPLOADED) {
      return 'Bond Payment Uploaded';
    }
    if (bondRequest?.statusCode?.code === BondStatusEnum.I352_VIEWED_BY_OFFICER) {
      return 'I-352 Under Review';
    }
    if (bondRequest?.statusCode?.code === BondStatusEnum.APPROVED_I352_REQUEST) {
      return 'I-352 Approved';
    }
    if (bondRequest?.statusCode?.code === BondStatusEnum.DENIED_I352_REQUEST) {
      return 'I-352 Denied';
    }
    if (bondRequest?.statusCode?.code === BondStatusEnum.BOND_WITHDRAWN) {
      return 'Withdrawn';
    }
    if (bondRequest?.statusCode?.code === BondStatusEnum.SUBJECT_RELEASED) {
      return 'Noncitizen Released';
    }

    return bondRequest?.statusCode?.code;
  }
}

export default BondStatusUtil;
