import Api from './Api';
import { IObligor, IObligorType } from './types';

class ObligorApi {
  static getObligor(): Promise<IObligor> {
    return Api.GET('/obligor/current');
  }

  static saveObligor(obligor: IObligor): Promise<IObligor> {
    const body = JSON.stringify(obligor);
    return Api.POST('/obligor/save', body).then((response) => response as IObligor);
  }

  static getObligorTypes(): Promise<IObligorType[]> {
    return Api.GET('/obligor/typecodes').then((response) => response as IObligorType[]);
  }
}

export default ObligorApi;
