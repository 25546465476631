import React, { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Col, Container, Row } from 'react-bootstrap';

export interface IFormModelSheetSignatureInputProps {
  onChange: (signatureImage: string) => void;
  onSign: () => void;
  scaleHeight?: number;
}

const FormModelSheetSignatureInput = ({
  onChange,
  onSign,
  scaleHeight,
}: IFormModelSheetSignatureInputProps) => {
  const [signatureData, setSignatureData] = useState<string>();
  const [isSignEnabled, setIsSignEnabled] = useState<boolean>(false);

  let sigRef: SignatureCanvas;

  useEffect(() => {
    if (signatureData) {
      onChange(signatureData.substring(signatureData.lastIndexOf(',') + 1));
      setIsSignEnabled(true);
    } else {
      setIsSignEnabled(false);
    }
  }, [signatureData]);

  const doClearSignaturePad = () => {
    sigRef.getSignaturePad().clear();
    setSignatureData(undefined);
  };

  const doScaleCanvas = (canvas: HTMLCanvasElement): HTMLCanvasElement => {
    let copy = document.createElement('canvas');
    copy.width = canvas.width;
    copy.height = scaleHeight || canvas.height;
    const ctx = copy.getContext('2d');
    if (ctx) {
      ctx.drawImage(
        canvas,
        0,
        0,
        canvas.width,
        canvas.height,
        0,
        0,
        copy.width,
        scaleHeight || copy.height,
      );
    } else {
      throw 'Failed to get canvas for trimmed clone';
    }

    return copy;
  };

  return (
    <>
      <div>
        <SignatureCanvas
          penColor='black'
          clearOnResize={false}
          dotSize={2}
          minWidth={0.5}
          maxWidth={1.5}
          canvasProps={{
            width: 250,
            height: 75,
            className: 'sigCanvas',
          }}
          onEnd={() => setSignatureData(doScaleCanvas(sigRef.getTrimmedCanvas()).toDataURL())}
          ref={(ref) => {
            if (ref) sigRef = ref;
          }}
        />
        <Container>
          <Row>
            <Col>
              <Button disabled={!isSignEnabled} onClick={onSign}>
                Sign
              </Button>
            </Col>
            <Col>
              <Button onClick={doClearSignaturePad}>Clear</Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default FormModelSheetSignatureInput;
