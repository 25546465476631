import React from 'react';
import { Container } from 'react-bootstrap';
import styles from '../../assets/styles/app.scss';
import { BackButton } from '../../components/layout/BackButton';

export interface IUnauthorizedPageProps {
  message?: string;
}

const UnauthorizedPage = ({ message }: IUnauthorizedPageProps) => {
  return (
    <div>
      <Container fluid className={styles.outerContainer}>
        <h3 className='py-4'>Unauthorized</h3>
        <div className={styles.contentContainer}>
          <p className='pb-5'>
            {message || 'You do not have authorization to access this resource'}
          </p>
          <BackButton />
        </div>
      </Container>
    </div>
  );
};

export default UnauthorizedPage;
