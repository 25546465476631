import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BackButton } from '../components/layout/BackButton';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../assets/styles/app.scss';

export const HelpAndSupport = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const topics = [
    {
      title: 'Login help',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean ' +
        'euismod bibendum laoreet. Proin gravida dolor sit amet lacus ' +
        'accumsan et viverra justo commodo. Proin sodales pulvinar ' +
        'tempor. Cum sociis natoque penatibus et magnis dis parturient ' +
        'montes, nascetur ridiculus mus. Nam fermentum, nulla luctus ' +
        'pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien ' +
        'nunc eget odio. Lorem ipsum dolor sit amet, consectetur ' +
        'adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit',
      contactType: 'Call',
      contactInfo: '###-###-####',
    },
    {
      title: 'Creating a new bond payment request',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean ' +
        'euismod bibendum laoreet. Proin gravida dolor sit amet lacus ' +
        'accumsan et viverra justo commodo. Proin sodales pulvinar ' +
        'tempor. Cum sociis natoque penatibus et magnis dis parturient ' +
        'montes, nascetur ridiculus mus. Nam fermentum, nulla luctus ' +
        'pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien ' +
        'nunc eget odio. Lorem ipsum dolor sit amet, consectetur ' +
        'adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit',
      contactType: 'Email',
      contactInfo: 'ICECeBONDS-Helpdesk@ice.dhs.gov',
    },
    {
      title: 'Help with browsers and devices',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean ' +
        'euismod bibendum laoreet. Proin gravida dolor sit amet lacus ' +
        'accumsan et viverra justo commodo. Proin sodales pulvinar ' +
        'tempor. Cum sociis natoque penatibus et magnis dis parturient ' +
        'montes, nascetur ridiculus mus. Nam fermentum, nulla luctus ' +
        'pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien ' +
        'nunc eget odio. Lorem ipsum dolor sit amet, consectetur ' +
        'adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit',
      contactType: 'Email',
      contactInfo: 'ICECeBONDS-Helpdesk@ice.dhs.gov',
    },
  ];

  return (
    <Container fluid className={styles.outerContainer}>
      <h3 className='py-4'>ICE CeBONDS Help and Support</h3>
      <div className={styles.contentContainer} style={{ flexWrap: 'wrap', wordWrap: 'break-word' }}>
        <Row>
          <Col>
            <p>
              You can easily find and get the help you need by navigating the topic areas below -
              allowed actions for further support are listed on the right:
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
            <input
              type='text'
              className={styles.liveSearchPlaceholder}
              placeholder='Search for a topic'
              onChange={(event) => {
                setSearchTerm(event.target.value);
              }}
            />
          </Col>
        </Row>
        <Row className='py-4'>
          <Col xs='12' md='3' lg='3'>
            <h5>Topic</h5>
          </Col>
          <Col xs='12' md='6' lg='7'>
            <h5>Description</h5>
          </Col>
          <Col xs='12' md='3' lg='2'>
            <h5>Actions</h5>
          </Col>
        </Row>

        {topics
          .filter((topic) => {
            if (searchTerm === '') {
              return topic;
            } else if (topic.title.toLowerCase().includes(searchTerm.toLowerCase())) {
              return topic;
            }
          })
          .map((topic, key) => {
            return (
              <div key={key}>
                <Row>
                  <Col xs='12' md='3' lg='3'>
                    <h6>{topic.title}</h6>
                  </Col>
                  <Col xs='12' md='6' lg='7'>
                    <p>{topic.description}</p>
                  </Col>
                  <Col xs='12' md='3' lg='2'>
                    <p>
                      {topic.contactType}:{' '}
                      {topic.contactType === 'Email' ? (
                        <a href={'mailto:' + topic.contactInfo}>{topic.contactInfo}</a>
                      ) : (
                        topic.contactInfo
                      )}
                    </p>
                  </Col>
                </Row>
              </div>
            );
          })}
        <hr className='pb-3' />
        <BackButton />
      </div>
    </Container>
  );
};
