import { BondPayment } from '../models/bond-data';
import Api from './Api';

class PaymentApi {
  static saveBondPayment(bondRequestId: number, file: FormData): Promise<BondPayment> {
    const endpoint = '/payment/paymentreceipt/add?bondRequestId=' + bondRequestId;
    return Api.POST_file(endpoint, file).then((response) => response as BondPayment);
  }

  static getBondPayment(bondRequestId: number): Promise<BondPayment> {
    return Api.GET('/payment/' + bondRequestId).catch((e) => undefined);
  }

  static downloadBondPaymentFile(bondRequestId: number): Promise<Blob> {
    return Api.GET_file('/payment/receipt/download/' + bondRequestId).catch((e) => undefined);
  }
}

export default PaymentApi;
