class NumberToWords {
  static getWords(theNumber: number): string {
    // Converts a number to words. For example, 123 returns One Hundred Twenty Three.
    // Negative numbers and numbers larger than 999,999,999 are not allowed.
    // Decimals are rounded to integers.

    let words = '';
    const ones = [
      '',
      'one ',
      'two ',
      'three ',
      'four ',
      'five ',
      'six ',
      'seven ',
      'eight ',
      'nine ',
    ];
    const tens = [
      '',
      '',
      'twenty ',
      'thirty ',
      'forty ',
      'fifty ',
      'sixty ',
      'seventy ',
      'eighty ',
      'ninety ',
    ];
    const teens = [
      'ten ',
      'eleven ',
      'twelve ',
      'thirteen ',
      'fourteen ',
      'fifteen ',
      'sixteen ',
      'seventeen ',
      'eighteen ',
      'nineteen ',
    ];
    const huns = [
      '',
      'one hundred ',
      'two hundred ',
      'three hundred ',
      'four hundred ',
      'five hundred ',
      'six hundred ',
      'seven hundred ',
      'eight hundred ',
      'nine hundred ',
    ];

    const numberStr = theNumber.toFixed(0).toString(); // Rounds to 0 decimal places and no punctuation

    if (numberStr.length > 9) {
      return 'exceeded 9 digits';
    }
    if (numberStr === '0') {
      return 'zero';
    }

    for (let i = numberStr.length; i > 0; i--) {
      let charIndex = numberStr.length - i; // Use length - digit place (i) to calc the character index

      if (i === 9 || i === 6 || i === 3) {
        words += huns[Number(numberStr.charAt(charIndex))]; // Get hundreds number using current digit
      }
      if (i === 8 || i === 5 || i === 2) {
        words += tens[Number(numberStr.charAt(charIndex))]; // Get tens number using current digit
      }
      if (i === 7 || i === 4 || i === 1) {
        // Make sure this ones number is not part of a teen number
        if (charIndex - 1 >= 0 && numberStr.charAt(charIndex - 1) === '1') {
          words += teens[Number(numberStr.charAt(charIndex))]; // Get teens number using current digit
        } else {
          words += ones[Number(numberStr.charAt(charIndex))]; // Get ones number using current digit
        }
      }

      if (i === 7) {
        words += 'million ';
      }
      if (i === 4) {
        words += 'thousand ';
      }
    }

    return words.trim();
  }
}

export default NumberToWords;
