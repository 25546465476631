import Api from './Api';
import { I352Form } from '../models/i352-form';
import { IACHform, IFedWireForm, IPaymentForm } from '../models/dgs-forms';

export class DgsApi {
  static DGS_ENDPOINT: string = '/dgsng';

  static getForm(modelName: string, payload: I352Form) {
    return Api.POST_DIRECT(
      '',
      this.DGS_ENDPOINT + `/forms/generate/${modelName}`,
      JSON.stringify(payload),
    ).then((response) => {
      console.log('DGS response', response);
      return response;
    });
  }

  static getPaymentForm(modelName: string, payload: IPaymentForm) {
    return Api.POST_DIRECT(
      '',
      this.DGS_ENDPOINT + `/forms/generate/${modelName}`,
      JSON.stringify(payload),
    ).then((response) => {
      console.log('DGS response', response);
      return response;
    });
  }

  static getFedWireForm(modelName: string, payload: IFedWireForm) {
    return Api.POST_DIRECT(
      '',
      this.DGS_ENDPOINT + `/forms/generate/${modelName}`,
      JSON.stringify(payload),
    ).then((response) => {
      console.log('DGS response', response);
      return response;
    });
  }

  static getACHForm(modelName: string, payload: IACHform) {
    return Api.POST_DIRECT(
      '',
      this.DGS_ENDPOINT + `/forms/generate/${modelName}`,
      JSON.stringify(payload),
    ).then((response) => {
      console.log('DGS response', response);
      return response;
    });
  }
}
