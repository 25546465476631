import Api from './Api';
import { BondableRequest } from '../models/bond-data';
import { WithdrawBondRequestSchema } from '../models/withdraw-bond-request';
import { ICeBondsSubject, IObligor } from './types';
import { IApiError } from '../contexts/ErrorContext';
import Utils from './Utils';

class SubjectSearchApi {
  static saveObligorForRequest(obligor: IObligor, bondRequestId: number): Promise<number> {
    const body = JSON.stringify(obligor);
    const endpoint = '/subject/updateRequestBmisObligor?bondRequestId=' + bondRequestId;
    return Api.POST(endpoint, body).then((response) => response as number);
  }

  static getBondsForUser(): Promise<BondableRequest[] | IApiError> {
    return Api.GET('/subject/userbonds').then((response) => {
      if ('length' in response) return response as BondableRequest[];
      else if (Utils.isError(response)) {
        return response as IApiError;
      } else {
        return { message: 'Unknown error getting bonds for current user' } as IApiError;
      }
    });
  }

  static getBondsByANumber(aNumber: string): Promise<BondableRequest[] | IApiError> {
    return Api.GET('/subject/bondrequest/list/' + aNumber).then((response) => {
      if ('length' in response) return response as BondableRequest[];
      else if (Utils.isError(response)) {
        return response as IApiError;
      } else {
        return { message: 'Unknown error for ' + aNumber } as IApiError;
      }
    });
  }

  static SubmitBondRequest(ceBondsSubject: ICeBondsSubject): Promise<BondableRequest | IApiError> {
    const body = JSON.stringify({
      firstName: ceBondsSubject.firstName,
      lastName: ceBondsSubject.lastName,
      anumber: ceBondsSubject.aNumber,
    });
    return Api.POST('/subject/bondrequest/submit', body).then(
      (response) => response as BondableRequest,
    );
  }

  static GetBondRequest(bondRequestId: number): Promise<BondableRequest> {
    return Api.GET('/subject/bondrequest/' + bondRequestId).catch((e) => undefined);
  }

  static SubmitContractAndPayment(bondRequestId: number): Promise<BondableRequest> {
    return Api.GET('/subject/submitI352Contract/' + bondRequestId).catch((e) => undefined);
  }

  static withdrawBondRequest(bondRequestId: number): Promise<BondableRequest | IApiError> {
    return Api.GET('/subject/withdrawBondRequest/' + bondRequestId).then((response) => {
      if ('error' in response) return response as IApiError;
      else return response as BondableRequest;
    });
  }
}

export default SubjectSearchApi;
