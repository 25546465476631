import { faChevronRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BondableRequest } from '../models/bond-data';
import { statusesForBundle, statusesForWithdraw } from '../models/status';

export const BondActionLinks = ({ bondRequest }: { bondRequest: BondableRequest }) => {
  const canDoWithdraw = statusesForWithdraw.includes(bondRequest.statusCode.code);
  const canDoBundle = statusesForBundle.includes(bondRequest.statusCode.code);

  if (!canDoWithdraw && !canDoBundle) {
    return <div>No actions are available for the current bond payment request.</div>;
  }
  return (
    <div>
      {canDoBundle && (
        <div>
          Your bond payment request has been approved by ICE. You may proceed to contract and
          payment.
        </div>
      )}
      <div>The following action(s) are available for this bond payment request:</div>
      {canDoBundle && (
        <div className='mt-1 mb-2'>
          <Link to='/bond-bundle' state={bondRequest} className='fw-bold'>
            Contract and Payment
            <FontAwesomeIcon icon={faChevronRight} className='ms-1 me-1' />
          </Link>
          <div className='ms-3'>
            Your bond payment request has been approved by ICE. You may proceed. Use this action to
            complete your I-352 contract and bond payment.
          </div>
          <div className='ms-3 fw-bold'>
            <FontAwesomeIcon className='me-1' icon={faInfoCircle} />
            You have not yet submitted this bond payment request. You must click the "Contract and
            Payment" link for this request to be processed.
          </div>
        </div>
      )}
      {canDoWithdraw && (
        <div className='mt-1'>
          <Link to='/withdraw-bond-request' state={bondRequest} className='fw-bold'>
            Withdraw Request
            <FontAwesomeIcon icon={faChevronRight} className='ms-1 me-1' />
          </Link>
          <div className='ms-3'>
            If you decide that you no longer want to proceed with this bond payment request, you may
            withdraw the request using this action.
          </div>
        </div>
      )}
    </div>
  );
};
