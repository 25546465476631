import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from 'react-bootstrap';
import styles from '../assets/styles/app.scss';
import { Link, useNavigate } from 'react-router-dom';
import { ICeBondsSubject } from '../api/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faHouse } from '@fortawesome/free-solid-svg-icons';

export const SearchForm = () => {
  const navigate = useNavigate();
  const [searchValidated, setSearchValidated] = useState(false);

  useEffect(() => {
    document.title = 'Start Bond Payment Request - ICE CeBONDS';
  }, []);

  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Preventing the page from reloading

    const form = event.currentTarget;
    if (form?.checkValidity()) {
      const ceBondsSubject: ICeBondsSubject = {
        firstName: form.firstName?.value,
        lastName: form.lastName?.value,
        aNumber: form.aNumber?.value.replace(/\D/g, ''), // Replace non-digits with nothing
      };
      console.log('navigating to bond status container ', ceBondsSubject);
      navigate('/bond-status-container', { state: ceBondsSubject });
    }

    setSearchValidated(true);
  };

  return (
    <Container className='mt-3'>
      <h3>Start Bond Payment Request</h3>
      <div className='mb-2'>
        You must know the noncitizen's A-Number (Alien Number). The A-Number must be exactly nine
        digits long. If the A-Number has fewer than nine digits, please add zeros at the beginning.
        You are also required to add the person's First and Last name.
      </div>
      <Form
        className={styles.contentContainer}
        noValidate
        validated={searchValidated}
        onSubmit={handleSearch}
      >
        <FormGroup as={Row} className='p-2' controlId='firstName'>
          <FormLabel column xs='12' md='3' lg='2'>
            <div>
              First Name <span className={styles.requiredIndicator}>*</span>
            </div>
          </FormLabel>
          <Col xs='12' md='7' lg='6' xl='5'>
            <FormControl type='text' name='firstName' placeholder='Enter first name' required />
            <Form.Control.Feedback type='invalid'>
              Please enter noncitizen first name.
            </Form.Control.Feedback>
          </Col>
          <Col xs='12' md='7' lg='4' />
        </FormGroup>
        <FormGroup as={Row} className='p-2' controlId='lastName'>
          <FormLabel column xs='12' md='3' lg='2'>
            <div>
              Last Name <span className={styles.requiredIndicator}>*</span>
            </div>
          </FormLabel>
          <Col xs='12' md='7' lg='6' xl='5'>
            <FormControl type='text' name='lastName' placeholder='Enter last name' required />
            <Form.Control.Feedback type='invalid'>
              Please enter noncitizen last name.
            </Form.Control.Feedback>
          </Col>
          <Col xs='12' md='7' lg='4' />
        </FormGroup>
        <FormGroup as={Row} className='p-2' controlId='aNumber'>
          <FormLabel column xs='12' md='3' lg='2'>
            <div>
              A-Number <span className={styles.requiredIndicator}>*</span>
            </div>
          </FormLabel>
          <Col xs='12' md='7' lg='6' xl='5'>
            <FormControl
              type='text'
              name='aNumber'
              placeholder='Enter A-Number'
              required
              pattern='\d{3}[\- .]?\d{3}[\- .]?\d{3}'
            />
            <Form.Control.Feedback type='invalid'>
              Please enter a valid A-Number.
            </Form.Control.Feedback>
          </Col>
          <Col xs='12' md='6' />
        </FormGroup>
        <div className='mt-3'>
          <Link to='/'>
            <Button className='me-3' variant='secondary'>
              Home
            </Button>
          </Link>
          <Button variant='primary' type='submit'>
            Start <FontAwesomeIcon icon={faChevronRight} className='ms-1' />
          </Button>
        </div>
      </Form>
      <p className='mt-3 note-text small'>* Required</p>
    </Container>
  );
};
