import React, { useContext, useEffect, useState } from 'react';
import { Button, Container, Form, Modal, Spinner, Table } from 'react-bootstrap';
import ObligorApi from '../api/ObligorApi';
import ObligorDocumentApi from '../api/ObligorDocumentApi';
import SubjectSearchApi from '../api/SubjectSearchApi';
import {
  ICeBondsSubject,
  IObligor,
  IObligorDocument,
  IObligorDocumentType,
  IObligorType,
} from '../api/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faChevronLeft,
  faDownload,
  faExclamationCircle,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { CeBondsUserContext } from '../contexts/UserContext';
import LoadingPage from './util/LoadingPage';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BondableRequest } from '../models/bond-data';
import ObligorDocumentTypeFilter from '../helper/ObligorDocumentTypeFilter';
import { SubjectDisplay } from '../components/SubjectDisplay';
import Utils from '../api/Utils';
import { IApiError, useErrorContext } from '../contexts/ErrorContext';
import ObligorEditModal from '../components/ObligorEditModal';

const ObligorPage = () => {
  const ceBondsUser = useContext(CeBondsUserContext);
  const [obligor, setObligor] = useState<IObligor>();
  const [obligorDocument, setObligorDocument] = useState<IObligorDocument>();
  const [isObligorDocBlank, setIsObligorDocBlank] = useState<boolean>(false);
  //const [obligorDocumentWithContent, setObligorDocumentWithContent] = useState<IObligorDocument>();
  const [obligorDocumentTypeList, setObligorDocumentTypeList] = useState<IObligorDocumentType[]>();
  const [obligorDocumentTypeListFiltered, setObligorDocumentTypeListFiltered] =
    useState<IObligorDocumentType[]>();
  const [isObligorBlank, setIsObligorBlank] = useState<boolean>(false);
  const [isObligorDocumentSaving, setIsObligorDocumentSaving] = useState<boolean>(false);
  const [isObligorDocumentDownloading, setIsObligorDocumentDownloading] = useState<boolean>();
  const [isBondRequestSaving, setIsBondRequestSaving] = useState<boolean>(false);
  const [fileControlFeedback, setFileControlFeedback] = useState<string>();

  const location = useLocation();
  const navigate = useNavigate();
  const error = useErrorContext();

  const ceBondsSubject = location.state as ICeBondsSubject;

  useEffect(() => {
    document.title = 'New Bond Payment Request - ICE CeBONDS';

    if (!obligor) {
      ObligorApi.getObligor()
        .then((cbo) => {
          if (cbo && cbo.obligorId) {
            setObligor(cbo);

            if (!obligorDocument) {
              ObligorDocumentApi.getRecentObligorDocument(cbo.obligorId).then((doc) => {
                if (doc && doc.obligorDocId) {
                  setObligorDocument(doc);
                } else {
                  setIsObligorDocBlank(true);
                }
              });
            }
          } else {
            setIsObligorBlank(true);
            setIsObligorDocBlank(true);
          }
        })
        .catch((reason) => {
          console.error('get obligor error ', reason);
        });
    }
  }, []);

  useEffect(() => {
    //when an obligor is added, then get the doc types for the user to add a document
    if (obligor) {
      if (!obligorDocumentTypeList) {
        ObligorDocumentApi.getObligorDocumentTypes().then((obligorDocumentTypes) =>
          setObligorDocumentTypeList(obligorDocumentTypes),
        );
      }
    }
  }, [obligor]);

  useEffect(() => {
    //when an obligor changes or the doc type list changes, then filter it here.
    if (obligor && obligor.typeCode && obligorDocumentTypeList) {
      setObligorDocumentTypeListFiltered(
        ObligorDocumentTypeFilter.filterDocumentTypesByObligorType(
          obligor.typeCode.code,
          obligorDocumentTypeList,
        ),
      );
    }
  }, [obligor, obligorDocumentTypeList]);

  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const handleDocumentModalClose = () => setShowDocumentModal(false);

  const handleSavedObligor = (savedObligor: IObligor | undefined) => {
    setObligor(savedObligor);
  };

  const handleShowDocumentModal = () => {
    setIsObligorDocumentSaving(false);
    setUploadDocumentValidated(false);
    setShowDocumentModal(true);
  };

  const [uploadDocumentValidated, setUploadDocumentValidated] = useState(false);
  const handleDocumentUpload = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    const form = event.currentTarget.form;
    const fileControl = form?.fileSelectControl;
    const file = fileControl.files[0];

    if (fileControl.validity.valueMissing) {
      setFileControlFeedback('Please select a file to upload.');
      fileControl.setCustomValidity('ValueMissing');
    } else if (file.size > 20000000) {
      // file size limit is 20 MB
      setFileControlFeedback('The select file is too large. The file size limit is 20 MB.');
      fileControl.setCustomValidity('TooLarge');
    } else if (file.type != 'application/pdf') {
      setFileControlFeedback('Type of selected file is not accepted. Accepted types: .pdf');
      fileControl.setCustomValidity('TypeNotAccepted');
    } else {
      form?.fileSelectControl.setCustomValidity('');
    }
    fileControl.reportValidity();

    if (form?.checkValidity() && obligor) {
      const docTypeCode = form?.docTypeControl.value;
      const formData = new FormData();
      formData.append('file', file);

      setIsObligorDocumentSaving(true);

      ObligorDocumentApi.saveObligorDocument(docTypeCode, formData)
        .then((obligorDoc) => {
          // obligor doc save succeeded
          setObligorDocument(obligorDoc);
          setIsObligorDocumentSaving(false);
          setShowDocumentModal(false);
        })
        .catch((reason) => {
          setIsObligorDocumentSaving(false);
          setShowDocumentModal(false);
          error.setError('save obligor document error ', reason);
        });
    }

    setUploadDocumentValidated(true);
  };

  const handleDocumentDownload = () => {
    console.log('downloading document...');
    if (obligorDocument) {
      setIsObligorDocumentDownloading(true);
      ObligorDocumentApi.getObligorDocumentWithContent(obligorDocument.obligorDocId).then(
        (docWithContent) => {
          //add code to download obligorDocumentWithContent.binary
          //      var blob = new Blob([bytes], { type: "application/pdf" });// change resultByte to bytes
          console.debug(docWithContent);
          if (docWithContent) {
            var blob = new Blob([Utils.base64ToArrayBuffer(docWithContent.binary)], {
              type: 'application/pdf',
            });

            var url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download', docWithContent.name);
            link.style.visibility = 'hidden';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            // show error
          }
          setIsObligorDocumentDownloading(false);
        },
      );
      //}
    } else {
      //display error message
    }
  };

  const handleSubmitBondRequest = () => {
    // if both obligor and document exist then allow the request to be submitted
    if (ceBondsUser && obligor && obligorDocument) {
      setIsBondRequestSaving(true);
      SubjectSearchApi.SubmitBondRequest(ceBondsSubject)
        .then((cbr) => {
          // bond payment request added. redirect to status page for this bond payment request.
          if (Utils.isError(cbr)) {
            const errorResponse = cbr as IApiError;
            error.setError('Failed to submit bond payment request: ' + errorResponse.message);
          } else if ('bondRequestId' in cbr) {
            navigate('/bond-request', { state: cbr.bondRequestId });
          } else {
            error.setError('Unknown internal error');
          }
        })
        .catch((reason) => {
          setIsBondRequestSaving(false);
          error.setError('save bond payment request error ', reason);
        });
    }
  };

  if ((!obligor && !isObligorBlank) || (!obligorDocument && !isObligorDocBlank)) {
    return <LoadingPage message={'Please Wait...'} />;
  }

  return (
    <Container className='mt-3 mb-5'>
      {/* <Trail step={1}></Trail> */}
      <h3>New Bond Payment Request</h3>
      <SubjectDisplay ceBondsSubject={ceBondsSubject} />
      <div className='mt-3'>
        <div>
          <span className='fw-bold'>Obligor Information</span>
        </div>
        {!obligor && (
          <div>
            We need your information before you can submit a bond payment request. Please provide
            your mailing address and other contact information. You will also be prompted to upload
            documentation to verify your identity.
          </div>
        )}
        {obligor && (
          <div>Please review your information before submitting this bond payment request.</div>
        )}
      </div>
      {obligor && (
        <div>
          <Table size='sm' borderless className='mb-2 obligor-table'>
            <tr>
              <td className='bg-light'>Your Status</td>
              <td>{obligor?.typeCode?.name}</td>
            </tr>
            <tr>
              <td className='bg-light'>
                {obligor?.typeCode?.code === 'Citizen' && <>SSN</>}
                {obligor?.typeCode?.code === 'LPR' && <>SSN</>}
                {obligor?.typeCode?.code === 'NonCitizen' && <>ITIN or A-Number</>}
                {(obligor?.typeCode?.code === 'NonProfit' ||
                  obligor?.typeCode?.code === 'LawFirm') && <>EIN</>}
              </td>
              <td>{obligor?.einTinNbr}</td>
            </tr>
            {obligor && obligor.name && (
              <tr>
                <td className='bg-light'>Org. Name</td>
                <td>{obligor.name}</td>
              </tr>
            )}
            <tr>
              <td className='bg-light'>Your Name</td>
              <td>
                {obligor?.lastName}, {obligor?.firstName}
              </td>
            </tr>
            <tr>
              <td className='bg-light'>Email</td>
              <td>{obligor?.personalEmail}</td>
            </tr>
            <tr>
              <td className='bg-light'>Phone</td>
              <td>{obligor?.phoneNbr}</td>
            </tr>
            <tr>
              <td className='bg-light align-top'>Mailing Address</td>
              <td>
                <div className='p-0'>{obligor?.street1}</div>
                {obligor?.street2 && <div className='p-0'>{obligor?.street2}</div>}
                {obligor?.street3 && <div className='p-0'>{obligor?.street3}</div>}
                <div className='p-0'>
                  {obligor?.city}, {obligor?.stateCd} {obligor?.zip}
                </div>
              </td>
            </tr>
          </Table>
        </div>
      )}
      <ObligorEditModal
        onSave={(savedObligor) => handleSavedObligor(savedObligor)}
        obligor={obligor}
        obligorEmail={ceBondsUser?.email}
        buttonText={obligor ? 'Edit' : 'Add'}
      />
      {obligor && (
        <div>
          <div className='mt-4'>
            <span className='fw-bold'>Identity Verification Document</span>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  Please provide one document that can be used to verify your identity. Only .pdf
                  files are accepted.
                </Tooltip>
              }
            >
              <a href='javascript:;' aria-label='Identity verification document tip'>
                <small>
                  <FontAwesomeIcon className='ms-1' icon={faQuestionCircle} />
                </small>
              </a>
            </OverlayTrigger>
          </div>
          {!obligorDocument && (
            <div className='mb-1'>
              <div className='mb-2'>
                <FontAwesomeIcon icon={faExclamationCircle} /> You have not uploaded a document for
                review. Please add a document.
              </div>
              <div>
                <Button size='sm' onClick={handleShowDocumentModal}>
                  Add Document
                </Button>
              </div>
            </div>
          )}
          {obligorDocument && (
            <div className='mb-1'>
              <div className='mb-2'>
                <FontAwesomeIcon icon={faCheckCircle} /> You have uploaded the following document
                for review.
              </div>
              <div className='mb-2'>
                <span>
                  {obligorDocument.name} ({obligorDocument.typeCode.description})
                </span>
                <Button
                  className='ms-2'
                  variant='secondary'
                  size='sm'
                  onClick={handleDocumentDownload}
                >
                  View <FontAwesomeIcon icon={faDownload} />
                </Button>
                <Button
                  className='ms-2'
                  variant='secondary'
                  size='sm'
                  onClick={handleShowDocumentModal}
                >
                  Replace
                </Button>
              </div>
              <div>
                {isObligorDocumentDownloading && (
                  <div>
                    <Spinner animation='border' role='status' size='sm' />
                    &nbsp; Please Wait...
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      <div className='mt-5'>
        <Link to='/subject'>
          <Button className='me-3' variant='secondary'>
            <FontAwesomeIcon icon={faChevronLeft} className='me-1' /> Back
          </Button>
        </Link>
        {obligor && obligorDocument && (
          <span>
            <Button
              className='me-3'
              variant='primary'
              onClick={handleSubmitBondRequest}
              disabled={isBondRequestSaving}
            >
              Submit Bond Payment Request
            </Button>
            {isBondRequestSaving && (
              <span>
                <Spinner animation='border' role='status' size='sm' className='me-1' />
                Please Wait...
              </span>
            )}
          </span>
        )}
      </div>
      <Modal show={showDocumentModal} onHide={handleDocumentModalClose}>
        <Form name='documentUploadForm' noValidate validated={uploadDocumentValidated}>
          <Modal.Header closeButton>
            <Modal.Title>Identity Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!obligorDocumentTypeList && (
              <div>
                <Spinner animation='border' role='status' size='sm' />
                Loading...
              </div>
            )}
            {obligorDocumentTypeList && (
              <div>
                {obligor?.typeCode?.code == 'NonProfit' && (
                  <div className='mb-3'>
                    Please combine the following items into a single PDF file. IRS Letter 947
                    (Letter of Determination) and SS-4 IRS Notification Letter (Employer
                    Identification Number (EIN) approval letter)
                  </div>
                )}
                {obligor?.typeCode?.code == 'LawFirm' && (
                  <div className='mb-3'>
                    SS-4 IRS Notification Letter (Employer Identification Number (EIN) approval
                    letter)
                  </div>
                )}
                <Form.Group controlId='formDocTypeSelect' className='mb-2'>
                  <Form.Select
                    name='docTypeControl'
                    className='form-control'
                    required
                    aria-label='Select Document Type'
                  >
                    <option value=''>Select Document Type</option>
                    {obligorDocumentTypeListFiltered?.map((obligorDocumentTypeItem) => (
                      <option value={obligorDocumentTypeItem.code}>
                        {obligorDocumentTypeItem.description}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type='invalid'>
                    Please select a document type.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId='formFile' className='mb-2'>
                  <Form.Control
                    type='file'
                    name='fileSelectControl'
                    accept='application/pdf'
                    required
                  />
                  <Form.Control.Feedback type='invalid'>
                    {fileControlFeedback}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {isObligorDocumentSaving && (
              <span className='mr-3'>
                <Spinner animation='border' role='status' size='sm' className='me-1' />
                Please Wait...
              </span>
            )}
            {obligorDocumentTypeList && (
              <Button
                variant='primary'
                onClick={handleDocumentUpload}
                disabled={isObligorDocumentSaving}
              >
                Upload File
              </Button>
            )}
            <Button variant='secondary' onClick={handleDocumentModalClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default ObligorPage;
