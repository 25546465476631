import Api from './Api';
import { ICeBondsUser } from './types';
import { IApiError } from '../contexts/ErrorContext';

class UserApi {
  static getCurrentUserInfo(): Promise<ICeBondsUser | IApiError> {
    return Api.GET('/user/current');
  }

  static saveUserInfo(oktaId: string, email: string): Promise<ICeBondsUser | IApiError> {
    const body = JSON.stringify({ oktaId: oktaId, email: email });
    return Api.POST('/user/save', body).then((response) => response as ICeBondsUser);
  }
}

export default UserApi;
