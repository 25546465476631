import React, { useEffect, useState } from 'react';
import { Button, Container, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUndo,
  faCircleInfo,
  faPlus,
  faChevronRight,
  faExclamationCircle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { BondableRequest } from '../models/bond-data';
import { Link, useLocation } from 'react-router-dom';
import { ICeBondsSubject } from '../api/types';
import { SubjectDisplay } from '../components/SubjectDisplay';
import BondStatusUtil from '../helper/BondStatusUtil';
import Utils from '../api/Utils';
import { statusesForDenied } from '../models/status';

interface BondStatusProps {
  bondRequestList: BondableRequest[];
}

export const BondStatus = ({ bondRequestList }: BondStatusProps) => {
  const location = useLocation();
  const ceBondsSubject = location.state as ICeBondsSubject;
  const [subjectHasActiveRequest, setSubjectHasActiveRequest] = useState(false);

  useEffect(() => {
    document.title = 'Your Bond Payment Requests for Noncitizen - ICE CeBONDS';

    for (var bondRequestItem of bondRequestList) {
      if (!statusesForDenied.includes(bondRequestItem.statusCode.code)) {
        setSubjectHasActiveRequest(true);
      }
    }
  }, []);

  return (
    <Container>
      <h4>Your Bond Payment Requests for Noncitizen</h4>
      <SubjectDisplay ceBondsSubject={ceBondsSubject} />
      {bondRequestList.length > 0 && bondRequestList[0].firstName ? (
        <div>
          <div className='mb-3'>
            Please review your bond payment request(s) for this noncitizen, and take desired
            actions, if available.
          </div>
          <Table size='sm' responsive striped borderless hover>
            <thead>
              <tr>
                <th>Date Created</th>
                <th>Bond Status</th>
              </tr>
            </thead>
            <tbody>
              {bondRequestList?.map((bondRequestItem) => (
                <tr key={bondRequestItem.bondRequestId} data-testid='result-row'>
                  <td>{Utils.formatDisplayDate(bondRequestItem.createDate)}</td>
                  <td>
                    <Link to='/bond-request' state={bondRequestItem.bondRequestId}>
                      {BondStatusUtil.getBondStatusDescription(bondRequestItem)}
                      <FontAwesomeIcon icon={faChevronRight} className='ms-1' />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className='fw-bold'>
          <FontAwesomeIcon icon={faCircleInfo} /> You do not have any bond payment requests for this
          noncitizen.
        </div>
      )}
      <div className='mt-3'>
        <Link to='/subject'>
          <Button variant='secondary'>
            <FontAwesomeIcon icon={faUndo} className='me-1' />
            Change Noncitizen
          </Button>
        </Link>
        {!subjectHasActiveRequest && (
          <>
            <Link to='/obligor' state={ceBondsSubject}>
              <Button className='ms-3' variant='primary' data-testid='new-bond-request-button'>
                <FontAwesomeIcon icon={faPlus} className='me-1' />
                Bond Payment Request
              </Button>
            </Link>
            {bondRequestList.length > 0 && (
              <div className='mt-2 fw-bold'>
                <FontAwesomeIcon className='me-1' icon={faInfoCircle} />
                Click the "+ Bond Payment Request" button if you need to make a new request or
                resubmit a request for this noncitizen.
              </div>
            )}
          </>
        )}
        {subjectHasActiveRequest && (
          <div className='mt-2 fw-bold'>
            <FontAwesomeIcon className='me-1' icon={faExclamationCircle} color={'orange'} />
            You already have active bond payment requests for this noncitizen. You cannot create a
            new bond payment request at this time for this noncitizen.
          </div>
        )}
      </div>
    </Container>
  );
};
