import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  InputGroup,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import ObligorApi from '../api/ObligorApi';
import { IObligor, IObligorType } from '../api/types';
import { IApiError, useErrorContext } from '../contexts/ErrorContext';
import USStateSelect from './form/USStateSelect';

export interface IObligorEditModal {
  onSave: (savedObligor: IObligor) => void;
  obligorEmail?: string;
  obligor?: IObligor;
  buttonText: string;
}

const ObligorEditModal = ({ onSave, obligorEmail, obligor, buttonText }: IObligorEditModal) => {
  const [obligorTypeList, setObligorTypeList] = useState<IObligorType[]>();
  const [isObligorSaving, setIsObligorSaving] = useState<boolean>(false);
  const [obligorTypeCd, setObligorTypeCd] = useState<string>();
  const [obligorStateCd, setObligorStateCd] = useState<string>();
  const [showObligorModal, setShowObligorModal] = useState(false);
  const handleObligorModalClose = () => setShowObligorModal(false);
  const [editObligorValidated, setEditObligorValidated] = useState(false);

  const [ssnDefault, setSSNDefault] = useState('');
  const [anumberDefault, setANumberDefault] = useState('');
  const [einDefault, setEINDefault] = useState('');
  const error = useErrorContext();

  const nameAddrRegex = '^[a-zA-Z0-9,\\.\\(\\):;#\'"\\- ]{1,35}$'; // Allows letters, numbers ,.():;#'"- and space.. Most special chars are not allowed. Allows 1 to 35 character length.
  const cityRegex = "^[a-zA-Z,\\.'\\- ]{1,18}$"; // Allows letters, numbers ,.'- and space.. Most special chars are not allowed. Allows 1 to 18 character length.
  const ssnRegex = '^\\d{3}[\\-]?\\d{2}[\\-]?\\d{4}$'; // Requires 9 digit SSN. Formatting is optional.
  const einRegex = '^\\d{2}[\\-]?\\d{7}$'; // Requires 9 digit EIN. Formatting is optional.
  const iTinOrANumberRegex = '^\\d{3}[\\-]?\\d{3}[\\-]?\\d{3}$'; // Requires 9 digits in either ###-###-#### or ###-###-### format. Formatting is optional.
  const phoneRegex = '^\\(?\\d{3}\\)?[ \\.\\-]?\\d{3}[ \\.\\-]?\\d{4}$'; // Requires 10 digit phone. Formatting is optional.
  const zipRegex = '^\\d{5}[\\-]?(\\d{4})?$'; // Requires 5 digits. Plus4 portion is optional.

  useEffect(() => {
    ObligorApi.getObligorTypes().then((obligorTypes) => setObligorTypeList(obligorTypes));
  }, []);

  const obligorIsOrg = (obligor: IObligor | undefined) => {
    if (obligor) {
      return obligor.typeCode?.code === 'NonProfit' || obligor.typeCode?.code === 'LawFirm';
    }

    return false;
  };

  const onChangeObligorTypeCd = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setObligorTypeCd(event.target.value);
  };

  const handleShowObligorModal = () => {
    setObligorTypeCd(obligor?.typeCode?.code);
    setObligorStateCd(obligor?.stateCd);

    if (obligor?.typeCode?.code === 'Citizen') {
      setSSNDefault(obligor.einTinNbr);
    }
    if (obligor?.typeCode?.code === 'LPR') {
      setSSNDefault(obligor.einTinNbr);
    }
    if (obligor?.typeCode?.code === 'NonCitizen') {
      setANumberDefault(obligor.einTinNbr);
    }
    if (obligor && obligorIsOrg(obligor)) {
      setEINDefault(obligor.einTinNbr);
    }

    setEditObligorValidated(false);
    setShowObligorModal(true);
  };

  const handleObligorSave = (event: { currentTarget: any }) => {
    const form = event.currentTarget.form;

    if (form.checkValidity()) {
      let tin = '';
      let orgName = '';
      if (form.obligorTypeControl.value === 'Citizen' || form.obligorTypeControl.value === 'LPR') {
        tin = form.ssnControl.value;
      }
      if (form.obligorTypeControl.value === 'NonCitizen') {
        tin = form.iTINControl.value;
      }
      if (
        form.obligorTypeControl.value === 'LawFirm' ||
        form.obligorTypeControl.value === 'NonProfit'
      ) {
        tin = form.einControl.value;
        orgName = form.obligorOrgNameControl.value;
      }

      setIsObligorSaving(true);

      const obligorToSave: IObligor = {
        obligorId: obligor ? obligor.obligorId : 0,
        userId: 0,
        einTinNbr: tin.replaceAll('-', ''),
        obligorTypeCode: form.obligorTypeControl.value,
        name: orgName,
        firstName: form.obligorFirstNameControl.value,
        lastName: form.obligorLastNameControl.value,
        companyEmail: '',
        personalEmail: obligorEmail || '',
        phoneNbr: form.phoneControl.value.replaceAll(/[^0-9]/g, ''),
        street1: form.adr1Control.value,
        street2: form.adr2Control.value,
        street3: form.adr3Control.value,
        city: form.cityControl.value,
        stateCd: obligorStateCd || '',
        zip: form.zipCodeControl.value.replaceAll('-', ''),
        countryCd: 'US',
        typeCode: null,
        user: null,
        bmisObligorId: null,
      };

      if (obligorToSave) {
        ObligorApi.saveObligor(obligorToSave)
          .then((cbo) => {
            // obligor save succeeded
            onSave(cbo);
            setIsObligorSaving(false);
            setShowObligorModal(false);
          })
          .catch((reason) => {
            setIsObligorSaving(false);
            setShowObligorModal(false);
            error.setError('save obligor error ', reason);
          });
      }
    }

    setEditObligorValidated(true);
  };

  return (
    <>
      <Button variant='secondary' size='sm' onClick={handleShowObligorModal}>
        {buttonText}
      </Button>
      <Modal show={showObligorModal} onHide={handleObligorModalClose}>
        <Form name='obligorEditForm' noValidate validated={editObligorValidated}>
          <Modal.Header closeButton>
            <Modal.Title>Obligor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='mb-3'>
              You will be the obligor for this bond payment request. Complete the following
              information as it applies to you.
            </div>
            <Row className='mb-2'>
              <Form.Group as={Col} md='6'>
                <div>Your Status</div>
                <Form.Select
                  name='obligorTypeControl'
                  className='form-control'
                  required
                  defaultValue={obligor?.typeCode?.code}
                  aria-label='Select Legal Entity'
                  onChange={onChangeObligorTypeCd}
                >
                  <option value=''>(Please Select)</option>
                  {obligorTypeList?.map((obligorTypeItem) => (
                    <option value={obligorTypeItem.code}>{obligorTypeItem.name}</option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type='invalid'>
                  Please select an posting type.
                </Form.Control.Feedback>
              </Form.Group>
              {(obligorTypeCd === 'Citizen' || obligorTypeCd === 'LPR') && (
                <Form.Group as={Col} md='6'>
                  <div>
                    <span>Enter your SSN</span>
                  </div>
                  <Form.Control
                    type='text'
                    name='ssnControl'
                    placeholder='SSN'
                    required
                    pattern={ssnRegex}
                    defaultValue={ssnDefault}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please enter a valid SSN. For example: 123-45-6789
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              {obligorTypeCd === 'NonCitizen' && (
                <Form.Group as={Col} md='6'>
                  <div>
                    <span>Enter ITIN or A-Number</span>
                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Tooltip>
                          You may enter your ITIN or A-Number. The Individual Taxpayer
                          Identification Number (ITIN) is a tax-processing number issued by the
                          Internal Revenue Service (IRS) to ensure that people, including
                          undocumented immigrants, pay taxes even if they do not have a Social
                          Security number (SSN) and regardless of their immigration status. The ITIN
                          is a nine-digit number that always begins with the number 9.
                        </Tooltip>
                      }
                    >
                      <a href='javascript:' aria-label='ITIN or A-Number tip'>
                        <small>
                          <FontAwesomeIcon className='ms-1' icon={faQuestionCircle} />
                        </small>
                      </a>
                    </OverlayTrigger>
                  </div>
                  <Form.Control
                    type='text'
                    name='iTINControl'
                    placeholder='ITIN or A-Number'
                    required
                    pattern={iTinOrANumberRegex}
                    defaultValue={anumberDefault}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please enter a valid ITIN or A-Number. For example: 123-45-6789
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              {(obligorTypeCd === 'LawFirm' || obligorTypeCd === 'NonProfit') && (
                <Form.Group as={Col} md='6'>
                  <div>
                    <span>Enter your EIN</span>
                  </div>
                  <Form.Control
                    type='text'
                    name='einControl'
                    placeholder='EIN'
                    required
                    pattern={einRegex}
                    defaultValue={einDefault}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please enter a valid EIN. For example: 12-3456789
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            </Row>
            {(obligorTypeCd === 'LawFirm' || obligorTypeCd === 'NonProfit') && (
              <div>
                <div className='mt-3'>Organization Name</div>
                <Form.Group>
                  <Form.Control
                    type='text'
                    name='obligorOrgNameControl'
                    placeholder='Org. Name'
                    required
                    pattern={nameAddrRegex}
                    maxLength={35}
                    defaultValue={obligor?.name}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please enter valid Org. Name. Special characters are not allowed.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            )}

            <div className='mt-3'>Your Name and Contact Information</div>
            <Row className='mt-2'>
              <Form.Group as={Col} md='6'>
                <Form.Control
                  type='text'
                  name='obligorFirstNameControl'
                  placeholder='First Name'
                  required
                  pattern={nameAddrRegex}
                  maxLength={35}
                  defaultValue={obligor?.firstName}
                />
                <Form.Control.Feedback type='invalid'>
                  Please enter a valid First Name. Special characters are not allowed.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md='6'>
                <Form.Control
                  type='text'
                  name='obligorLastNameControl'
                  placeholder='Last Name'
                  required
                  pattern={nameAddrRegex}
                  maxLength={35}
                  defaultValue={obligor?.lastName}
                />
                <Form.Control.Feedback type='invalid'>
                  Please enter a valid Last Name. Special characters are not allowed.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <div className='mt-2'>{obligorEmail}</div>
            <Form.Group className='mt-2'>
              <InputGroup>
                <InputGroup.Text id='basic-addon1'>+1</InputGroup.Text>
                <Form.Control
                  type='text'
                  name='phoneControl'
                  placeholder='Phone Number'
                  required
                  pattern={phoneRegex}
                  defaultValue={obligor?.phoneNbr}
                />
                <Form.Control.Feedback type='invalid'>
                  Please enter a valid phone number. For example, 123-456-7890
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <div className='mt-3'>
              {obligorTypeCd === 'LawFirm' || obligorTypeCd === 'NonProfit'
                ? 'Organization'
                : 'Your'}{' '}
              Mailing Address
            </div>
            <Form.Text muted>Bond return checks are mailed to this address</Form.Text>
            <Form.Group className='mb-2'>
              <Form.Control
                type='text'
                id='adr1Control'
                placeholder='Street 1'
                required
                pattern={nameAddrRegex}
                maxLength={35}
                defaultValue={obligor?.street1}
              />
              <Form.Control.Feedback type='invalid'>
                Please enter a valid street. Special characters are not allowed.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-2'>
              <Form.Control
                type='text'
                name='adr2Control'
                placeholder='Street 2'
                className='mb-2'
                pattern={nameAddrRegex}
                maxLength={35}
                defaultValue={obligor?.street2}
              />
              <Form.Control.Feedback type='invalid'>
                Please enter a valid street. Special characters are not allowed.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-2'>
              <Form.Control
                type='text'
                name='adr3Control'
                placeholder='Street 3'
                className='mb-2'
                pattern={nameAddrRegex}
                maxLength={35}
                defaultValue={obligor?.street3}
              />
              <Form.Control.Feedback type='invalid'>
                Please enter a valid street. Special characters are not allowed.
              </Form.Control.Feedback>
            </Form.Group>
            <Row className='mb-2'>
              <Form.Group as={Col} md='6' controlId='validationCustom03'>
                <Form.Control
                  type='text'
                  name='cityControl'
                  placeholder='City'
                  required
                  pattern={cityRegex}
                  maxLength={18}
                  defaultValue={obligor?.city}
                />
                <Form.Control.Feedback type='invalid'>
                  Please provide a valid city.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md='3' controlId='validationCustom04'>
                <USStateSelect
                  defaultValue={obligorStateCd}
                  required
                  invalidFeedback={'State is required'}
                  onChange={(state) => setObligorStateCd(state.abbreviation)}
                  id={'stateInput'}
                  mini={true}
                />
              </Form.Group>
              <Form.Group as={Col} md='3' controlId='validationCustom05'>
                <Form.Control
                  type='text'
                  name='zipCodeControl'
                  placeholder='Zip'
                  required
                  pattern={zipRegex}
                  defaultValue={obligor?.zip}
                />
                <Form.Control.Feedback type='invalid'>
                  Please provide a valid zip.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {isObligorSaving && (
              <div>
                <Spinner animation='border' role='status' size='sm' className='me-1' />
                Please Wait...
              </div>
            )}
            <Button variant='primary' onClick={handleObligorSave} disabled={isObligorSaving}>
              Save
            </Button>
            <Button variant='secondary' onClick={handleObligorModalClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ObligorEditModal;
