import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Form } from 'react-bootstrap';
import SubjectSearchApi from '../api/SubjectSearchApi';
import { BondableRequest } from '../models/bond-data';
import { UserBondStatus } from './UserBondStatus';
import { useCeBondsUserContext } from '../contexts/UserContext';
import LoadingPage from './util/LoadingPage';
import { IApiError, useErrorContext } from '../contexts/ErrorContext';
import Utils from '../api/Utils';

export const LandingPage = () => {
  const [userBondRequests, setUserBondRequests] = useState<BondableRequest[]>();
  const ceBondsUser = useCeBondsUserContext();

  const error = useErrorContext();

  useEffect(() => {
    document.title = 'Home - ICE CeBONDS';
  }, []);

  useEffect(() => {
    if (ceBondsUser?.userId) {
      SubjectSearchApi.getBondsForUser()
        .then((responseData: BondableRequest[] | IApiError) => {
          if (Utils.isError(responseData)) {
            const errorData = responseData as IApiError;
            error.setError(errorData.message);
            setUserBondRequests([]);
          } else if ('length' in responseData) {
            if (responseData.length === 0) {
              setUserBondRequests([]);
            } else {
              setUserBondRequests(responseData);
            }
          }
        })
        .catch((err) => {
          error.setError(`Failed to search: ${err}`);
          setUserBondRequests([]);
          console.error('SubjectSearchApi.getBondsByUserIdApi failed', err);
        });
    }
  }, [ceBondsUser]);

  const [filteraNumber, setFilteraNumber] = useState<string>('');
  const onChangeFilteraNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilteraNumber(event.target.value);
  };

  const handleFilter = () => {
    if (ceBondsUser) {
      SubjectSearchApi.getBondsByANumber(filteraNumber).then(
        (responseData: BondableRequest[] | IApiError) => {
          if (Utils.isError(responseData)) {
            const errorData = responseData as IApiError;
            error.setError(errorData.message);
            setUserBondRequests([]);
          } else if ('length' in responseData) {
            if (responseData.length === 0) {
              setUserBondRequests([]);
            } else {
              setUserBondRequests(responseData);
            }
          }
        },
      );
    }
  };

  if (!userBondRequests) return <LoadingPage message={'Loading bond payment requests...'} />;

  return (
    <Container fluid={true}>
      <h3 className='pt-3 pb-3' text-align='center'>
        Welcome to ICE CeBONDS!
      </h3>
      {userBondRequests?.length === 0 && (
        <p>
          No recent bond payment requests were found for you. Start a new bond payment request if
          needed.
        </p>
      )}
      {userBondRequests?.length > 0 && (
        <p>Take action on your recent bond payment requests or start a new bond payment request.</p>
      )}
      {userBondRequests && userBondRequests.length > 0 && (
        <div>
          {/* <div>
            Go To
            <Form.Control
              size="sm"
              type='text'
              name='anumberControl'
              placeholder='A-Number'
              onChange={onChangeFilteraNumber}
            />
            <Button variant='secondary' size="sm" onClick={handleFilter}>
              Go
            </Button>
          </div> */}
          <UserBondStatus bondRequestList={userBondRequests} />
        </div>
      )}
      <div className='pt-3'>
        <Link to='/subject'>
          <Button variant='primary'>
            <FontAwesomeIcon icon={faPlus} className='me-1' /> Bond Payment Request
          </Button>
        </Link>
      </div>
    </Container>
  );
};
