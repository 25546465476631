const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

class Utils {
  static base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }

  static downloadPDF(base64Input: string, name: string) {
    var blob = new Blob([Utils.base64ToArrayBuffer(base64Input)], {
      type: 'application/pdf',
    });

    var url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', name);
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  static getMonthString(now: Date): string {
    return MONTH_NAMES[now.getMonth()];
  }

  static getDateSuffix(now: Date): string {
    const date = now.getDate();
    if (date > 20 || date < 10) {
      switch (date % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
      }
    }
    return 'th';
  }

  /**
   * Checks to see if an object is an IApiError object, and if so, is the error
   * field set to true
   *
   * @param input
   */
  static isError(input: any): boolean {
    return 'error' in input && input.error === true;
  }

  static padZero(input: number): string {
    return input < 10 && input > 0 ? '0' + input : '' + input;
  }

  static formatDisplayDate(displayDate: string): string {
    if (displayDate) {
      return new Intl.DateTimeFormat('default').format(Date.parse(displayDate));
    }

    return 'NOT_A_DATE';
  }
}

export default Utils;
