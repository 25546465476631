import React from 'react';
import { Alert, Spinner } from 'react-bootstrap';

export interface IErrorPageProps {
  message: string;
  heading?: string;
  slim?: boolean;
  fullWidth?: boolean;
  showClose?: boolean;
  onBannerDismiss?: () => void;
}

const ErrorPage = ({
  heading,
  message,
  slim,
  fullWidth,
  showClose,
  onBannerDismiss,
}: IErrorPageProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: slim ? '0rem' : '2rem',
      }}
      className={`${fullWidth && 'w-100'}`}
    >
      <Alert
        variant='danger'
        className={`${fullWidth && 'w-100'}`}
        dismissible={showClose}
        onClose={onBannerDismiss}
      >
        {heading && <Alert.Heading>{heading}</Alert.Heading>}
        <p>{message}</p>
      </Alert>
    </div>
  );
};

export default ErrorPage;
