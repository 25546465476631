import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import DHSIcon from '../assets/images/DHS_LOGO.png';
import styles from '../assets/styles/app.scss';
import packageJson from '../../package.json';

export const Footer = () => {
  return (
    <Navbar bg='dhs-blue' expand='lg' variant='dark' collapseOnSelect fixed={'bottom'}>
      <Navbar.Brand>
        <a href='http://www.ice.gov' target='_blank'>
          <img src={DHSIcon} alt='Logo' className='d-inline-block my-auto mx-3' height='50px' />
        </a>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='responsive-navbar-nav' />
      <Navbar.Collapse className='justify-content-end'>
        <Nav.Item>
          <Navbar.Text className='text-muted px-4'>
            <small>Version: {packageJson.version}</small>
          </Navbar.Text>
        </Nav.Item>
        <Nav.Item>
          <a href='http://www.ice.gov/bonds' target='_blank'>
            <Navbar.Text className='text-light px-4'>HELP AND SUPPORT</Navbar.Text>
          </a>
        </Nav.Item>
        <Nav.Item>
          <a href='http://www.ice.gov/site-policies' target='_blank'>
            <Navbar.Text className='text-light px-4'>PRIVACY POLICY</Navbar.Text>
          </a>
        </Nav.Item>
      </Navbar.Collapse>
    </Navbar>
  );
};
