import {
  IAddCommentAttachmentResponse,
  IBondCommentAttachment,
  IBondRequestComments,
} from './types';
import Api from './Api';
import Utils from './Utils';
import { IApiError } from '../contexts/ErrorContext';

class CommentsApi {
  static getComments(bondRequestId: number): Promise<IBondRequestComments | IApiError> {
    return Api.GET(`/comments/current/${bondRequestId}`)
      .then((response) => {
        if (Utils.isError(response)) return response as IApiError;
        else return response as IBondRequestComments;
      })
      .catch((reason) => {
        console.error('CommentsApi.getComments ', reason);
        const error: IApiError = {
          error: true,
          message: 'Failed to load comments',
          timestamp: new Date().getMilliseconds(),
          statusCode: 999,
        };
        return error;
      });
  }

  static addComment(
    bondRequestId: number,
    comment: string,
    drsId?: number,
    docType?: string,
  ): Promise<boolean | IApiError> {
    let payload: any = {
      comment,
    };

    if (drsId && docType) {
      payload = { ...payload, drsId, docType };
    }

    return Api.POST(`/comments/new/${bondRequestId}`, JSON.stringify(payload))
      .then((response) => {
        if (Utils.isError(response)) return response as IApiError;
        else return true;
      })
      .catch((reason) => {
        console.error('CommentsApi.addComments ', reason);
        const error: IApiError = {
          error: true,
          message: 'Failed to add comment',
          timestamp: new Date().getMilliseconds(),
          statusCode: 999,
        };
        return error;
      });
  }

  static addCommentAttachment(
    bondRequestId: number,
    file: FormData,
  ): Promise<IAddCommentAttachmentResponse | IApiError> {
    return Api.POST_file(`/comments/new/attachment/${bondRequestId}`, file)
      .then((response) => {
        if (Utils.isError(response)) return response as IApiError;
        else return response as IAddCommentAttachmentResponse;
      })
      .catch((reason) => {
        console.error('CommentsApi.addCommentAttachment ', reason);
        const error: IApiError = {
          error: true,
          message: 'Failed to add attachment',
          timestamp: new Date().getMilliseconds(),
          statusCode: 999,
        };
        return error;
      });
  }

  static getCommentAttachment(drsId: number): Promise<IBondCommentAttachment | IApiError> {
    return Api.GET(`/comments/attachment/${drsId}`).then((response) => {
      if ('error' in response) return response as IApiError;
      else return response as IBondCommentAttachment;
    });
  }
}

export default CommentsApi;
