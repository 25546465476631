import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Container, Spinner } from 'react-bootstrap';
import { IApiError, useErrorContext } from '../contexts/ErrorContext';
import Utils from '../api/Utils';
import { BondableRequest } from '../models/bond-data';
import { ICeBondsSubject } from '../api/types';
import { SubjectDisplay } from '../components/SubjectDisplay';
import SubjectSearchApi from '../api/SubjectSearchApi';

const WithdrawRequestPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const error = useErrorContext();

  const bondableRequest = location.state as BondableRequest;
  const [ceBondsSubject, setCeBondsSubject] = useState<ICeBondsSubject>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    document.title = 'Withdraw Bond Payment Request - ICE CeBONDS';

    if (bondableRequest) {
      setCeBondsSubject({
        firstName: bondableRequest.firstName,
        lastName: bondableRequest.lastName,
        aNumber: bondableRequest.anumber,
      });
    }
  }, []);

  const handleWithdraw = () => {
    setIsSubmitting(true);
    SubjectSearchApi.withdrawBondRequest(bondableRequest.bondRequestId).then((cbr) => {
      if (Utils.isError(cbr)) {
        const errorResponse = cbr as IApiError;
        error.setError(errorResponse.message);
      } else {
        navigate('/bond-request', { state: bondableRequest.bondRequestId });
      }
    });
  };

  return (
    <Container>
      <h3 className='mt-3'>Withdraw Bond Payment Request</h3>
      <SubjectDisplay ceBondsSubject={ceBondsSubject} />
      <div className='mt-3'>
        Withdrawing your bond payment request for this noncitizen will change the status to
        "Withdrawn". You will not be able to take futher action after it is withdrawn. Any pending
        or active ICE reviews will be canceled.
      </div>
      <div className='mt-3'>
        <Link to='/bond-request' state={bondableRequest.bondRequestId}>
          <Button className='me-3' variant='secondary'>
            Back
          </Button>
        </Link>
        <Button onClick={handleWithdraw} disabled={isSubmitting}>
          Withdraw
        </Button>
        {isSubmitting && (
          <span className='ms-1'>
            <Spinner animation='border' role='status' size='sm' />
            Please Wait...
          </span>
        )}
      </div>
    </Container>
  );
};

export default WithdrawRequestPage;
