import React from 'react';
//import './index.css';
//import reportWebVitals from './reportWebVitals';
import './assets/ero-theme.scss';

import { createRoot } from 'react-dom/client';
import CeBonds from './CeBonds';

const container = document.getElementById('root');
const root = createRoot(container!);
// root.render(<Router>
//     <App/>
// </Router>)

root.render(<CeBonds />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
