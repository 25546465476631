import React from 'react';
import { Container } from 'react-bootstrap';
import { BackButton } from '../components/layout/BackButton';
import styles from '../assets/styles/app.scss';

export const PrivacyPolicy = () => {
  return (
    <div>
      <Container fluid className={styles.outerContainer}>
        <h3 className='py-4'>ICE CeBONDS Privacy Policy</h3>
        <div className={styles.contentContainer}>
          <div className={styles.emptyContentBlock}></div>
          <BackButton />
        </div>
      </Container>
    </div>
  );
};
