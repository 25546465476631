export enum BondStatusEnum {
  SUBJECT_NOT_BONDABLE = 'SUBJECT_NOT_BONDABLE',
  BOND_WITHDRAWN = 'BOND_WITHDRAWN',
  APPROVED_BONDABLE_REQUEST = 'APPROVED_BONDABLE_REQUEST',
  DENIED_BONDABLE_REQUEST = 'DENIED_BONDABLE_REQUEST',
  NEW_I352_REQUEST = 'NEW_I352_REQUEST', // UI controlled status
  I352_SIGNED_BY_OBLIGOR = 'I352_SIGNED_BY_OBLIGOR', // UI controlled status
  BOND_PAYMENT_UPLOADED = 'BOND_PAYMENT_UPLOADED', // UI controlled status
  SUBMITTED_I352_REQUEST = 'SUBMITTED_I352_REQUEST', // UI controlled status
  I352_CREATED_IN_EARM = 'I352_CREATED_IN_EARM',
  I352_VIEWED_BY_OFFICER = 'I352_VIEWED_BY_OFFICER',
  APPROVED_I352_REQUEST = 'APPROVED_I352_REQUEST',
  DENIED_I352_REQUEST = 'DENIED_I352_REQUEST',
  PARTIAL_I352_REQUEST = 'PARTIAL_I352_REQUEST', // Not used
  INVALID_I352 = 'INVALID_I352', // Not used
  NEW_BONDABLE_REQUEST = 'NEW_BONDABLE_REQUEST',
  NEW_BONDABLE_REQUEST_RECEIVED = 'NEW_BONDABLE_REQUEST_RECEIVED', // Not used
  NEW_BOND_REQUEST_VIEWED_BY_OFFICER = 'NEW_BOND_REQUEST_VIEWED_BY_OFFICER',
  NEW_BOND_REQUEST_RECEIVED_AFTER_HRS = 'NEW_BOND_REQUEST_RECEIVED_AFTER_HRS',
  SUBJECT_RELEASED = 'SUBJECT_RELEASED',
}

export const statusesForBundle = [
  'APPROVED_BONDABLE_REQUEST',
  'I352_SIGNED_BY_OBLIGOR',
  'DENIED_I352_REQUEST',
];

export const statusesForWithdraw = [
  'NEW_BONDABLE_REQUEST_RECEIVED',
  'NEW_BOND_REQUEST_VIEWED_BY_OFFICER',
  'NEW_BOND_REQUEST_RECEIVED_AFTER_HRS',
  'NEW_BONDABLE_REQUEST',
  'APPROVED_BONDABLE_REQUEST',
  'I352_SIGNED_BY_OBLIGOR',
  'BOND_PAYMENT_UPLOADED',
];

export const statusesForComments = [
  'APPROVED_BONDABLE_REQUEST',
  'I352_SIGNED_BY_OBLIGOR',
  'BOND_PAYMENT_UPLOADED',
  'APPROVED_I352_REQUEST',
  'I352_CREATED_IN_EARM',
  'SUBMITTED_I352_REQUEST',
  'NEW_I352_REQUEST',
  'PARTIAL_I352_REQUEST',
  'I352_VIEWED_BY_OFFICER',
];

export const statusesForDenied = [
  'SUBJECT_NOT_BONDABLE',
  'BOND_WITHDRAWN',
  'DENIED_BONDABLE_REQUEST',
  'DENIED_I352_REQUEST',
];
