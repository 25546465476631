import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { useCeBondsUserContext } from '../../contexts/UserContext';
import ObligorI352Summary from './ObligorI352Summary';
import { INonCitizenAddress, IObligor } from '../../api/types';
import ObligorApi from '../../api/ObligorApi';
import LoadingPage from '../util/LoadingPage';
import { BondableRequest } from '../../models/bond-data';
import USStateSelect from '../../components/form/USStateSelect';
import { I352Form } from '../../models/i352-form';
import { DgsApi } from '../../api/DgsApi';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NumberToWords from '../../helper/NumberToWords';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

export interface IBondContractDataEntryProps {
  bondRequest?: BondableRequest;
}

export interface IBondFormEntryFields {
  [key: string]: any;
}

/**
 * Data Entry screen for the ICE i-352 form
 *
 * Reference: https://balsamiq.cloud/sfi3thl/p7xywi9/r97D9
 *
 * @param props
 * @constructor
 */
const BondContractDataEntry = ({ bondRequest: propBondRequest }: IBondContractDataEntryProps) => {
  const ceBondsUser = useCeBondsUserContext();
  const [obligor, setObligor] = useState<IObligor>();
  const [formValid, setFormValid] = useState(false);
  const [address1Input, setAddress1Input] = useState<string>('');
  const [address2Input, setAddress2Input] = useState<string>('');
  const [address3Input, setAddress3Input] = useState<string>('');
  const [cityInput, setCityInput] = useState<string>('');
  const [stateInput, setStateInput] = useState<string>('');
  const [zipCodeInput, setZipCodeInput] = useState<string>('');
  const [phoneNumberInput, setPhoneNumberInput] = useState<string>('');

  const navigate = useNavigate();
  const location = useLocation();

  const bondRequest = propBondRequest || (location.state as BondableRequest);

  const bondAmount = bondRequest?.bondAmount?.toString();

  useEffect(() => {
    if (!obligor) {
      ObligorApi.getObligor().then((value) => setObligor(value));
    }

    if (bondRequest) {
      // set default values for input form
      setAddress1Input(bondRequest.address1);
      setAddress2Input(bondRequest.address2);
      setAddress3Input(bondRequest.address3);
      setCityInput(bondRequest.residenceCity);
      setStateInput(bondRequest.residenceState);
      setZipCodeInput(bondRequest.residenceZip);
      setPhoneNumberInput(bondRequest.residencePhone);
    }
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const inputForm = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (!inputForm.checkValidity()) {
    } else {
      const fullPayload: IBondFormEntryFields = {
        address1: address1Input,
        address2: address2Input,
        address3: address3Input,
        city: cityInput,
        state: stateInput,
        zipCode: zipCodeInput,
        phoneNumber: phoneNumberInput,
        ceBondsUser: { ...ceBondsUser },
        obligor: { ...obligor },
        bondableRequest: { ...bondRequest },
      };
      console.log('working with ', fullPayload);

      let alienToReside = fullPayload.address1;
      if (fullPayload.address2) alienToReside += `, ${fullPayload.address2}`;
      if (fullPayload.address3) alienToReside += `, ${fullPayload.address3}`;
      alienToReside += `, ${fullPayload.city}, ${fullPayload.state || '?state?'} ${
        fullPayload.zipCode
      }`;

      let obligorAddress = fullPayload.obligor.street1;
      if (fullPayload.obligor.street2) obligorAddress += `, ${fullPayload.obligor.street2}`;
      if (fullPayload.obligor.street3) obligorAddress += `, ${fullPayload.obligor.street3}`;

      let paragraphGNum = '';
      let paragraphGCaption = '';
      if (bondRequest.bondType === 'D') {
        paragraphGNum = 'G.1';
        paragraphGCaption = 'BOND CONDITIONED UPON THE DELIVERY OF AN ALIEN';
      }
      if (bondRequest.bondType === 'VD') {
        paragraphGNum = 'G.2';
        paragraphGCaption = 'BOND CONDITIONED UPON THE VOLUNTARY DEPARTURE OF AN ALIEN';
      }
      if (bondRequest.bondType === 'OS') {
        paragraphGNum = 'G.3';
        paragraphGCaption = 'ORDER OF SUPERVISION BOND';
      }
      if (bondRequest.bondType === 'MS') {
        paragraphGNum = 'G.4';
        paragraphGCaption = 'MAINTENANCE OF STATUS AND DEPARTURE BOND';
      }

      const obligorName = obligor?.name || obligor?.lastName + ', ' + obligor?.firstName;

      const form: I352Form = {
        // Top of document -> A-File No or Visa No
        form_BondedAlienFileNo: fullPayload.bondableRequest.anumber,

        // Part A
        form_NAME: obligorName,
        form_tax3: fullPayload.obligor.einTinNbr,
        form_address: obligorAddress,
        form_citystatezip: `${fullPayload.obligor.city}, ${fullPayload.obligor.stateCd} ${fullPayload.obligor.zip}`,
        form_tele: `${fullPayload.obligor.phoneNbr}`,

        // Part B
        form_alienname:
          `${fullPayload.bondableRequest.lastName}, ${fullPayload.bondableRequest.firstName}`.toUpperCase(),
        form_alientoreside: alienToReside,
        form_dobarrival: fullPayload.bondableRequest.arrivalDate,
        form_nationality: fullPayload.bondableRequest.nationality,
        form_dobandcountry: fullPayload.bondableRequest.birthDate,
        form_currentlocation: fullPayload.bondableRequest.detentionLocationName,
        form_cob: fullPayload.bondableRequest.countryOfBirth,
        form_means: bondRequest.portOfEntry || bondRequest.arrivalState || '--',
        form_teleresidence: fullPayload.phoneNumber,

        // Part C
        form_us: NumberToWords.getWords(fullPayload.bondableRequest.bondAmount),
        form_dollars: fullPayload.bondableRequest.bondAmount?.toString(),
        form_facts: paragraphGNum,
        form_riderCaption: paragraphGCaption,

        // Part F
        form_cashtrea: obligorName,

        // Does not appear to be anywhere in the document
        //form_my: 'my field',
        // Part A -> Address (if different from Obligor) - line 1
        //form_Address12: 'Address 12',
        // Part A -> Address (if different from Obligor) - line 2
        //form_address1: 'Address 13',
        // Does not appear to be anywhere in the document
        //form_bonddate: 'bond date',
      };

      let apiPayload: { [key: string]: string } = {};
      for (const key of Object.keys(form)) {
        const newKey: string = key.replace('form_', '') || '';
        apiPayload[newKey] = form[key as keyof I352Form] || '';
      }

      DgsApi.getForm('ice-i352', apiPayload)
        .then((formResponse) => {
          if (formResponse['message'] === 'SUCCESS') {
            // add the new address from the input form to the bondRequest object. This address will be included on the service call when I352 is signed.
            const nonCitizenAddress: INonCitizenAddress = {
              address1: address1Input,
              address2: address2Input,
              address3: address3Input,
              residenceCity: cityInput,
              residenceState: stateInput,
              residenceZip: zipCodeInput.replaceAll(/[^0-9]/g, ''),
              residencePhone: phoneNumberInput.replaceAll(/[^0-9]/g, ''),
            };
            navigate('/i352/sign', {
              state: {
                fileName: formResponse.fileName,
                formData: apiPayload,
                bondRequest,
                nonCitizenAddress: nonCitizenAddress,
              },
            });
          } else {
            console.error('Failed to generate PDF: ', formResponse);
          }
        })
        .catch((err) => console.log('failed to generate PDF', err));
    }
    setFormValid(true);
  };

  const renderAddressInput = () => {
    return (
      <>
        <Form.Group className='mb-2'>
          <Form.Label>Home address</Form.Label>
          <Form.Control
            type='text'
            defaultValue={address1Input}
            placeholder='Address Line 1'
            required
            maxLength={40}
            onChange={(event) => setAddress1Input(event.target.value)}
          />
          <Form.Control.Feedback type='invalid'>Address is required</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='mb-2'>
          <Form.Control
            type='text'
            defaultValue={address2Input}
            placeholder='Address Line 2'
            maxLength={40}
            onChange={(event) => setAddress2Input(event.target.value)}
          />
        </Form.Group>
        <Form.Group className='mb-2'>
          <Form.Control
            type='text'
            defaultValue={address3Input}
            placeholder='Address Line 3'
            maxLength={40}
            onChange={(event) => setAddress3Input(event.target.value)}
          />
        </Form.Group>
        <Form.Group className='mb-2'>
          <Form.Label>City</Form.Label>
          <Form.Control
            type='text'
            defaultValue={cityInput}
            required
            maxLength={23}
            onChange={(event) => setCityInput(event.target.value)}
          />
          <Form.Control.Feedback type='invalid'>City is required</Form.Control.Feedback>
        </Form.Group>
        <Row className='mb-3'>
          <Form.Group as={Col} sm='8'>
            <USStateSelect
              defaultValue={stateInput}
              required
              invalidFeedback={'State is required'}
              onChange={(state) => setStateInput(state.abbreviation)}
              id={'stateInput'}
            />
          </Form.Group>
          <Form.Group as={Col} sm='4' controlId='validationCustom05'>
            <Form.Label>Zip Code</Form.Label>
            <Form.Control
              type='text'
              defaultValue={zipCodeInput}
              required
              pattern='^\d{5}([\-])?(\d{4})?$'
              onChange={(event) => setZipCodeInput(event.target.value)}
            />
            <Form.Control.Feedback type='invalid'>Valid zip is required</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Form.Group className='mb-2'>
          <Form.Label>Phone</Form.Label>
          <InputGroup>
            <InputGroup.Text id='basic-addon1'>+1</InputGroup.Text>
            <Form.Control
              type='text'
              defaultValue={phoneNumberInput}
              required
              pattern='^\(?\d{3}\)?[\s.\-]?\d{3}[\s.\-]?\d{4}$'
              onChange={(event) => setPhoneNumberInput(event.target.value)}
            />
            <Form.Control.Feedback type='invalid'>Valid phone is required</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </>
    );
  };

  const renderBondRequest = () => {
    return (
      <>
        <div>
          <h6>Noncitizen to reside at</h6>
          <div>
            <div>{renderAddressInput()}</div>
          </div>
        </div>
      </>
    );
  };

  if (!obligor) {
    return <LoadingPage message={'Please Wait...'} />;
  }

  return (
    <Container>
      <Form noValidate={true} className={'w-100'} validated={formValid} onSubmit={handleSubmit}>
        <ObligorI352Summary obligor={obligor} bondRequest={bondRequest} setObligor={setObligor} />
        <hr />
        {renderBondRequest()}
        <hr />
        <div>
          <Link to='/bond-bundle' state={bondRequest}>
            <Button variant='secondary' className='me-3'>
              Cancel
            </Button>
          </Link>
          <Button variant='primary' type={'submit'}>
            Next <FontAwesomeIcon icon={faChevronRight} className='ms-1' />
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default BondContractDataEntry;
