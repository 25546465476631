import { Table } from 'react-bootstrap';
import { BondableRequest } from '../models/bond-data';
import { Link } from 'react-router-dom';
import BondStatusUtil from '../helper/BondStatusUtil';
import Utils from '../api/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';

interface BondStatusProps {
  bondRequestList: BondableRequest[];
}

export const UserBondStatus = ({ bondRequestList }: BondStatusProps) => {
  return (
    <div>
      <Table size='sm' responsive striped borderless hover>
        <thead>
          <tr>
            <th>Date Created</th>
            <th>A-Number</th>
            <th>Last, First Name</th>
            <th>Bond Status</th>
          </tr>
        </thead>
        <tbody>
          {bondRequestList?.map((bondRequestItem) => (
            <tr key={bondRequestItem.bondRequestId} data-testid='result-row'>
              <td>{Utils.formatDisplayDate(bondRequestItem.createDate)}</td>
              <td>{bondRequestItem.anumber}</td>
              <td>
                {bondRequestItem.lastName}, {bondRequestItem.firstName}
              </td>
              <td>
                <Link to='/bond-request' state={bondRequestItem.bondRequestId}>
                  {BondStatusUtil.getBondStatusDescription(bondRequestItem)}{' '}
                  <FontAwesomeIcon icon={faChevronRight} />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
